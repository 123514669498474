import React from 'react';
import Modal from 'react-modal';

import { Flex, View, Alert } from '@aws-amplify/ui-react';

import { HistoryReviewCard, HistorySampleCard } from '../ui-components';

function timeConverter(UNIX_timestamp) {
  var a = new Date(UNIX_timestamp);
  var months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  //   var sec = a.getSeconds();
  var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min;
  return time;
}

const truncateText = (text, length) => {};

const HistoryCard = ({ historyItem, breakpoint }) => {
  const { id, created, operations, request } = historyItem;
  //   console.log({ operations });
  const { review, rewrite, generate_sample } = operations;
  // {body, result}
  const reviewType =
    review && rewrite ? 'rewritereview' : review ? 'review' : 'rewrite';
  return (
    <View>
      {review || rewrite ? (
        <HistoryReviewCard
          breakpoint={breakpoint}
          overrides={{
            HistoryReviewCard: {
              width: 'unset',
            },
            createdTime: {
              fontFamily: '',
              children: timeConverter(created),
            },
            Instruction: {
              fontFamily: '',
              children: request.prompt,
            },
            vocabulary: {
              fontFamily: '',
              children: request.vocabulary,
              ...(request.vocabulary && request.vocabulary.length
                ? { display: 'flex' }
                : { display: 'None' }),
            },
            wordCount: {
              fontFamily: '',
              children: `${request.words_count} words`,
              ...(request.words_count && request.words_count.length
                ? { display: 'flex' }
                : { display: 'None' }),
            },
            writeType: {
              fontFamily: '',
              children: request.write_type,
              ...(request.write_type && request.write_type.length
                ? { display: 'flex' }
                : { display: 'None' }),
            },
            Rating38303503: {
              value: operations.review?.Content?.score,
            },
            Reviews38303504: {
              fontFamily: '',
              children: 'Content', //operations.review.Content.review,
            },
            Rating38303606: {
              value: operations.review?.Grammar?.score,
            },
            Reviews38303607: {
              fontFamily: '',
              children: 'Grammar', //operations.review.Grammar.review,
            },
            Rating38303619: {
              value: operations.review?.Purpose?.score,
            },
            Reviews38303620: {
              fontFamily: '',
              children: 'Purpose', // operations.review.Purpose.review,
            },
            Rating38303632: {
              value: operations.review?.Structure?.score,
            },
            Reviews38303633: {
              fontFamily: '',
              children: 'Structure', //operations.review.Structure.review,
            },
            Rating38303645: {
              value: operations.review?.Style?.score,
            },
            Reviews38303646: {
              fontFamily: '',
              children: 'Style', //operations.review.Style.review,
            },
            Rating38303658: {
              value: operations.review?.Vocabulary?.score,
            },
            Reviews38303659: {
              fontFamily: '',
              children: 'Vocabulary', // operations.review.Vocabulary.review,
            },
            OriginalContent: {
              fontFamily: '',
              children: request.content,
            },
            RewriteContent: {
              fontFamily: '',
              children: operations.rewrite?.rewrite,
            },
            'Original Writing': {
              fontFamily: '',
            },
            Rewrite: {
              fontFamily: '',
            },
            ...(reviewType === 'review'
              ? {
                  Divider38303884: {
                    display: 'None',
                  },
                  'Frame 442': {
                    display: 'None',
                  },
                }
              : {}),
            ...(reviewType === 'rewrite'
              ? {
                  'Bottom Row': {
                    display: 'None',
                  },
                }
              : {}),
          }}
        />
      ) : (
        <HistorySampleCard
          breakpoint={breakpoint}
          overrides={{
            HistorySampleCard: {
              width: 'unset',
            },
            createdTime: {
              fontFamily: '',
              children: timeConverter(created),
            },
            Instruction: {
              fontFamily: '',
              children: request.instruction,
            },
            vocabulary: {
              fontFamily: '',
              children: request.vocabulary,
              ...(request.vocabulary && request.vocabulary.length
                ? { display: 'flex' }
                : { display: 'None' }),
            },
            wordCount: {
              fontFamily: '',
              children: `${request.words_count} words`,
              ...(request.words_count && request.words_count.length
                ? { display: 'flex' }
                : { display: 'None' }),
            },
            writeType: {
              fontFamily: '',
              children: request.write_type,
              ...(request.write_type && request.write_type.length
                ? { display: 'flex' }
                : { display: 'None' }),
            },
            OriginalContent: {
              fontFamily: '',
              children: generate_sample,
            },
          }}
        />
      )}
    </View>
  );
};

export { HistoryCard };
