/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from 'react';
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from '@aws-amplify/ui-react/internal';
import {
  Badge,
  Button,
  Flex,
  Icon,
  Image,
  SearchField,
  Text,
  View,
} from '@aws-amplify/ui-react';
import { ProfileMenu } from './ProfileMenu';
export default function NavBarResponsive(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        'Star 1': {},
        'icon 2': {},
        fullLogo: {},
        label: {},
        Logo: {},
        Button37683080: {},
        Button37693101: {},
        Button37683093: {},
        'Frame 32129767076': {},
        SearchField: {},
        Badge: {},
        Vector: {},
        Icon: {},
        image: {},
        'Frame 32129767081': {},
        NavBarResponsive: {},
      },
      variantValues: { breakpoint: 'xxl' },
    },
    {
      overrides: {
        'Star 1': {},
        'icon 2': { display: 'block' },
        fullLogo: { display: 'none' },
        label: {},
        Logo: {},
        Button37683080: {},
        Button37693101: {},
        Button37683093: {},
        'Frame 32129767076': {},
        SearchField: {},
        Badge: { display: 'none' },
        Vector: {},
        Icon: {},
        image: {},
        'Frame 32129767081': {},
        NavBarResponsive: {
          gap: '10px',
          width: '480px',
          padding: '0px 20px 0px 20px',
        },
      },
      variantValues: { breakpoint: 'small' },
    },
    {
      overrides: {
        'Star 1': {},
        'icon 2': {},
        fullLogo: {},
        label: {},
        Logo: { display: 'none' },
        Button37683080: { padding: '8px 20px 8px 20px' },
        Button37693101: {},
        Button37683093: {},
        'Frame 32129767076': {},
        SearchField: {},
        Badge: { display: 'none' },
        Vector: {},
        Icon: {},
        image: {},
        'Frame 32129767081': { gap: '0', justifyContent: 'center' },
        NavBarResponsive: {
          gap: '0',
          width: '388px',
          justifyContent: 'flex-start',
          padding: '0px 0px 0px 0px',
        },
      },
      variantValues: { breakpoint: 'base' },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {},
  );
  return (
    <Flex
      gap="20px"
      direction="row"
      width="1440px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="0px 32px 0px 32px"
      backgroundColor="rgba(46,79,79,1)"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, 'NavBarResponsive')}
    >
      <Flex
        gap="2px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="29px 0px 29px 0px"
        display="flex"
        {...getOverrideProps(overrides, 'Logo')}
      >
        <Icon
          width="18px"
          height="18px"
          viewBox={{ minX: 0, minY: 0, width: 18, height: 18 }}
          paths={[
            {
              d: 'M8.12249 1.60573C8.50171 0.911796 9.49829 0.911796 9.87751 1.60573L10.9602 3.58693C11.1657 3.96292 11.5879 4.16622 12.0099 4.09244L14.234 3.70368C15.0129 3.56751 15.6343 4.34666 15.3282 5.07581L14.4543 7.15757C14.2884 7.55264 14.3927 8.00946 14.7135 8.29344L16.4041 9.78987C16.9963 10.314 16.7745 11.2856 16.0136 11.5009L13.8412 12.1156C13.4289 12.2323 13.1367 12.5986 13.1147 13.0265L12.9989 15.2813C12.9583 16.071 12.0604 16.5034 11.4176 16.0427L9.58254 14.7275C9.23429 14.4779 8.76571 14.4779 8.41746 14.7275L6.58236 16.0427C5.9396 16.5034 5.04172 16.071 5.00113 15.2813L4.88526 13.0265C4.86327 12.5986 4.57112 12.2323 4.15884 12.1156L1.98638 11.5009C1.22546 11.2856 1.0037 10.314 1.59585 9.78987L3.28646 8.29344C3.6073 8.00946 3.71156 7.55264 3.54571 7.15757L2.6718 5.07581C2.36571 4.34666 2.98706 3.56751 3.76604 3.70368L5.99007 4.09244C6.41214 4.16622 6.83431 3.96292 7.03978 3.58693L8.12249 1.60573Z',
              fill: 'rgba(255,114,98,1)',
              fillRule: 'nonzero',
            },
          ]}
          display="none"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          {...getOverrideProps(overrides, 'Star 1')}
        ></Icon>
        <Image
          width="36.58px"
          height="30px"
          display="none"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          {...getOverrideProps(overrides, 'icon 2')}
        ></Image>
        <Image
          width="126.58px"
          height="30px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          {...getOverrideProps(overrides, 'fullLogo')}
        ></Image>
        <Text
          fontFamily="Inter"
          fontSize="24px"
          fontWeight="600"
          color="rgba(229,229,229,1)"
          lineHeight="30px"
          textAlign="left"
          display="none"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="WritingBooster"
          {...getOverrideProps(overrides, 'label')}
        ></Text>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, 'Frame 32129767076')}
      >
        <Button
          width="unset"
          height="88px"
          shrink="0"
          size="default"
          isDisabled={false}
          variation="link"
          children="Review"
          {...getOverrideProps(overrides, 'Button37683080')}
        ></Button>
        <Button
          width="unset"
          height="88px"
          shrink="0"
          size="default"
          isDisabled={false}
          variation="link"
          children="Writing Samples"
          {...getOverrideProps(overrides, 'Button37693101')}
        ></Button>
        <Button
          width="unset"
          height="88px"
          shrink="0"
          size="default"
          isDisabled={false}
          variation="link"
          children="Tips"
          {...getOverrideProps(overrides, 'Button37683093')}
        ></Button>
      </Flex>
      <Flex
        gap="32px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-end"
        alignItems="center"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, 'Frame 32129767081')}
      >
        <SearchField
          width="300px"
          height="unset"
          display="none"
          shrink="0"
          backgroundColor="rgba(255,255,255,1)"
          placeholder="Placeholder"
          size="default"
          isDisabled={false}
          labelHidden={true}
          variation="default"
          {...getOverrideProps(overrides, 'SearchField')}
        ></SearchField>
        <Badge
          width="unset"
          height="unset"
          shrink="0"
          size="small"
          variation="success"
          children="50 credits"
          {...getOverrideProps(overrides, 'Badge')}
        ></Badge>
        <View
          width="24px"
          height="24px"
          display="none"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, 'Icon')}
        >
          <Icon
            width="16px"
            height="19.5px"
            viewBox={{ minX: 0, minY: 0, width: 16, height: 19.5 }}
            paths={[
              {
                d: 'M8 19.5C9.1 19.5 10 18.6 10 17.5L6 17.5C6 18.6 6.9 19.5 8 19.5ZM14 13.5L14 8.5C14 5.43 12.37 2.86 9.5 2.18L9.5 1.5C9.5 0.67 8.83 0 8 0C7.17 0 6.5 0.67 6.5 1.5L6.5 2.18C3.64 2.86 2 5.42 2 8.5L2 13.5L0 15.5L0 16.5L16 16.5L16 15.5L14 13.5ZM12 14.5L4 14.5L4 8.5C4 6.02 5.51 4 8 4C10.49 4 12 6.02 12 8.5L12 14.5Z',
                fill: 'rgba(229,229,229,1)',
                fillRule: 'nonzero',
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="10.42%"
            bottom="8.33%"
            left="16.67%"
            right="16.67%"
            {...getOverrideProps(overrides, 'Vector')}
          ></Icon>
        </View>
        <ProfileMenu />
        {/* <Image
          width="45px"
          height="45px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          borderRadius="160px"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          {...getOverrideProps(overrides, "image")}
        ></Image> */}
      </Flex>
    </Flex>
  );
}
