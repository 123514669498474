import { Image, Divider } from '@aws-amplify/ui-react';
import { Menu, MenuItem, MenuButton } from '@aws-amplify/ui-react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';

import { useProfileContext } from '../hookers/profileContext';
import { imageMapping } from '../images';

export const ProfileMenu = () => {
  const { route, signOut } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);
  const navigate = useNavigate();
  const { profile } = useProfileContext();

  const SignOut = () => {
    signOut();
    navigate('/login');
  };

  const SignIn = () => {
    navigate('/login');
  };

  const BuyCredits = () => {
    navigate('/buycredits');
  };

  const EditProfile = () => {
    navigate('/editprofile');
  };

  const ViewHistory = () => {
    navigate('/viewhistory');
  };
  return (
    <Menu
      trigger={
        <Image
          width="45px"
          height="45px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          borderRadius="160px"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src={imageMapping[profile?.avatar || 1]}
          // src={imageMapping[1]}
          backgroundColor="rgb(203, 228, 222)"
        ></Image>
      }
    >
      <MenuItem onClick={EditProfile}>Edit Profile</MenuItem>
      <MenuItem onClick={ViewHistory}>View History</MenuItem>
      <MenuItem onClick={BuyCredits}>Buy Credits</MenuItem>
      <Divider />
      {route === 'authenticated' ? (
        <MenuItem onClick={SignOut}>Sign Out</MenuItem>
      ) : (
        <MenuItem onClick={SignIn}>Sign In</MenuItem>
      )}
    </Menu>
  );
};
