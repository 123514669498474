import { TIPS } from './tips';

const ANY = 'any';
const vocabularyInit = ANY;
const vocabularyOptions = [
  vocabularyInit,
  'kindergarten',
  ...Array.from({ length: 12 }, (_, i) => `year ${i + 1}`),
  'university and above',
];

const writeTypeInit = ANY;
const writeTypeOptions = [
  writeTypeInit,
  'narrative',
  'descriptive',
  'expository',
  'persuasive',
  'reflective',
  'technical',
  'creative',
];

const wordsCountInit = ANY;
const wordsCountOptions = [
  wordsCountInit,
  '100 below',
  '100 to 200',
  '200 to 400',
  '400 to 1000',
  '1000 above',
];

export {
  ANY,
  TIPS,
  vocabularyInit,
  wordsCountInit,
  writeTypeInit,
  vocabularyOptions,
  writeTypeOptions,
  wordsCountOptions,
};
