import { View, Flex, useBreakpointValue } from '@aws-amplify/ui-react';
import {} from '../ui-components';
export function Careers() {
  const breakpoint = useBreakpointValue({
    base: 'base',
    small: 'small',
    medium: 'medium',
    large: 'large',
    xl: 'xl',
    xxl: 'xxl',
  });
  console.log({ breakpoint });

  return (
    <View className="App-header">
      <Flex
        direction="column"
        style={{
          width: breakpoint === 'xxl' ? 1480 : '90%',

          paddingTop: 12,
          paddingBottom: 12,
        }}
      >
        <Flex
          direction="column"
          style={{ padding: 24, backgroundColor: 'rgba(255,255,255,1)' }}
        >
          <h1>Careers</h1>
          <p>
            Welcome to our website. We appreciate your interest in our company,
            but at this time we currently do not have any open positions
            available.
          </p>
          <p>
            While we are not actively hiring for any roles at the moment, we
            encourage you to check back periodically for any updates. We are
            always interested in connecting with talented and motivated
            individuals who share our values and vision, and we may have
            opportunities in the future that could be a good fit for you.
          </p>
          <p>
            In the meantime, we recommend that you take some time to learn more
            about our company and what we do. We believe that our mission and
            values are what set us apart from other companies, and we are proud
            of the work we do to make a positive impact in our industry and the
            wider community.
          </p>
          <p>
            If you are interested in staying up-to-date on our company's latest
            news and developments, we encourage you to sign up for our
            newsletter or follow us on social media. This will give you the
            opportunity to learn more about our company culture, our team, and
            the exciting projects we are working on.
          </p>
          <p>
            Thank you again for your interest in our company. We wish you all
            the best in your job search, and we hope to have the opportunity to
            connect with you in the future.
          </p>
        </Flex>
      </Flex>
    </View>
  );
}
