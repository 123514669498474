import { useState, useEffect, useCallback } from 'react';

import { postApi } from './utils';

const limit = 10; //page limit

export const useHistory = () => {
  const [history, setHistory] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getHistory = useCallback(async (lastKey = undefined) => {
    if (!isLoading) {
      setIsLoading(true);
      await postApi(
        '/api/history',
        { limit, ...(lastKey ? { lastKey } : {}) },
        (err) => {
          // console.log({ err });
          setError('Get history error: ' + err);
        },
        (data) => {
          // console.log({ data });
          setHistory(data);
        },
      );
      setIsLoading(false);
    }
  }, []);

  // load for the first time
  useEffect(() => {
    getHistory();
    return () => {};
  }, []);

  return { history, isLoading, error, getHistory };
};
