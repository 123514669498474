import { useState } from 'react';

import { Flex, View, useBreakpointValue } from '@aws-amplify/ui-react';
import { MessageBox } from '../components/MessageBox.js';

import {
  WritingPannel,
  ContextControllers,
  Reviews,
  RewriteCard,
  RewriteControllers,
} from '../ui-components';

import { useProfileContext } from '../hookers/profileContext';

import {
  ANY,
  vocabularyInit,
  wordsCountInit,
  writeTypeInit,
  vocabularyOptions,
  writeTypeOptions,
  wordsCountOptions,
} from '../data';
import { postApi } from '../hookers/utils.js';

const countWords = (str1) => {
  return str1.trim().split(/\s+/).length;
};

export function Review() {
  const [vocabulary, setVocabulary] = useState(vocabularyInit);
  const [writeType, setWriteType] = useState(writeTypeInit);
  const [wordsCount, setWordsCount] = useState(wordsCountInit);
  const [prompt, setPrompt] = useState('');
  const [content, setContent] = useState('');

  const [contentHasError, setContentHasError] = useState(false);

  const [isReviewLoading, setIsReviewLoading] = useState(false);
  const [isRewriteLoading, setIsRewriteLoading] = useState(false);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [msgHeading, setMsgHeading] = useState('');
  const [msgContent, setMsgContent] = useState('');

  const [contentReview, setContentReview] = useState({ review: '', score: 0 });
  const [grammarReview, setGrammarReview] = useState({ review: '', score: 0 });
  const [purposeReview, setPurposeReview] = useState({ review: '', score: 0 });
  const [structureReview, setStructureReview] = useState({
    review: '',
    score: 0,
  });
  const [styleReview, setStyleReview] = useState({ review: '', score: 0 });
  const [vocabularyReview, setVocabularyReview] = useState({
    review: '',
    score: 0,
  });
  const [reviewed, setReviewed] = useState(false);

  const [rewriteResult, setRewriteResult] = useState([
    { origin: '', rewrite: '', review: '' },
  ]);
  const [rewritten, setRewritten] = useState(false);

  const [rewriteContent, setRewriteContent] = useState('');

  const [historyId, setHistoryId] = useState(null);

  const {
    deductCredit,
    profile: { credits },
  } = useProfileContext();

  const breakpoint = useBreakpointValue({
    base: 'base',
    small: 'small',
    medium: 'medium',
    large: 'large',
    xl: 'xl',
    xxl: 'xxl',
  });

  const openModal = (heading, content) => {
    setMsgHeading(heading);
    setMsgContent(content);
    setIsOpen(true);
  };

  const setReview = (data) => {
    const {
      Content: contentReviewResponse,
      Structure: structureReviewResponse,
      Style: styleReviewResponse,
      Grammar: grammarReviewResponse,
      Purpose: purposeReviewResponse,
      Vocabulary: vocabularyReviewResponse,
    } = data;
    setContentReview(contentReviewResponse);
    setGrammarReview(grammarReviewResponse);
    setPurposeReview(purposeReviewResponse);
    setStructureReview(structureReviewResponse);
    setStyleReview(styleReviewResponse);
    setVocabularyReview(vocabularyReviewResponse);
    setReviewed(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const reviewClick = async () => {
    if (!content || countWords(content) > 2000) {
      setContentHasError(true);
      return;
    }
    if (!credits || credits <= 0) {
      openModal('Info', 'Please buy more credits to continue this operation');
      return;
    }
    setIsReviewLoading(true);
    let data, error;
    await postApi('/api/review', {
      ...(historyId ? { historyId } : {}),
      requestBody: {
        vocabulary: vocabulary !== ANY ? vocabulary : '',
        write_type: writeType !== ANY ? writeType : '',
        words_count: wordsCount !== ANY ? wordsCount : '',
        prompt,
        content,
      },
    }, (err) => {
      error = err;
    },
    (d) => {
      data = d;
    });

    setIsReviewLoading(false);
    if (error) {
      if (error === 'Ran out of credits') {
        openModal('Info', 'Please buy more credits to continue this operation');
      } else {
        openModal('Info', error);
      }
    } else {
      const { historyId, consumedCredit, result } = data;
      deductCredit(consumedCredit);
      if (!result) {
        openModal(
          'Info',
          "Sorry we can't review this article, maybe modify your writing and try again",
        );
      } else {
        setReview(result);
        setHistoryId(historyId);
      }
    }
  };

  const rewriteClick = async (format) => {
    if (!content) {
      setContentHasError(true);
      return;
    }
    if (!credits || credits <= 0) {
      openModal('Info', 'Please buy more credits to continue this operation');
      return;
    }
    setIsRewriteLoading(true);
    let data, error;
    await postApi('/api/rewrite', {
      ...(historyId ? { historyId } : {}),
      requestBody: {
        vocabulary: vocabulary !== ANY ? vocabulary : '',
        write_type: writeType !== ANY ? writeType : '',
        words_count: wordsCount !== ANY ? wordsCount : '',
        prompt,
        format,
        content,
      },
    }, (err) => {
      error = err;
    },
    (d) => {
      data = d;
    });

    setIsRewriteLoading(false);
    if (error) {
      if (error === 'Ran out of credits') {
        openModal('Info', 'Please buy more credits to continue this operation');
      } else {
        openModal('Info', error);
      }
    } else {
      if (data?.result) {
        const {
          historyId,
          consumedCredit,
          result: { rewrite, review_rewrite },
        } = data;
        if (!rewrite || !review_rewrite) {
          openModal(
            'Info',
            "Sorry we can't rewrite this article, maybe modify your writing and try again",
          );
        } else {
          setHistoryId(historyId);
          setRewriteContent(rewrite);
          setRewriteResult(review_rewrite);
          setRewritten(true);
          deductCredit(consumedCredit);
        }
      } else {
        openModal('Info', 'try again');
      }
    }
  };

  const rewriteCards = () => (
    <div>
      {rewriteContent && (
        <WritingPannel
          overrides={{
            WritingPannel: {
              width: 'unset',
            },
            TextAreaField: {
              isDisabled: true,
              // labelHidden: true,
              label: 'Rewriting Result',
              value: rewriteContent,
              rows: 8,
            },
            label: {
              children: '',
            },
          }}
        />
      )}
      {rewriteResult &&
        rewriteResult.map(({ origin, rewrite, review }, index) => (
          <div key={`rewritecard-${index}`}>
            <RewriteCard
              breakpoint={breakpoint}
              overrides={{
                RewriteCard: {
                  width: 'unset',
                },
                TextAreaField37513024: {
                  placeholder: '',
                  // defaultValue: '',
                  value: origin,
                },
                TextAreaField37513079: {
                  placeholder: '',
                  // defaultValue: '',
                  value: rewrite,
                },
                TextAreaField37513086: {
                  placeholder: '',
                  // defaultValue: '',
                  value: review,
                },
              }}
            ></RewriteCard>
          </div>
        ))}
    </div>
  );

  // console.log({ breakpoint });
  // console.log({ contentReview });

  return (
    <View className="App-header">
      <Flex
        direction="column"
        style={{
          paddingTop: 12,
          paddingBottom: 12,
          width: breakpoint === 'xxl' ? 1480 : '90%',
        }}
      >
        <WritingPannel
          overrides={{
            WritingPannel: {
              width: 'unset',
            },
            TextAreaField: {
              hasError: contentHasError,
              errorMessage: 'Empty content or content length out of limit',
              onChange: (e) => {
                if (e.currentTarget.value) {
                  setContentHasError(false);
                }
                setContent(e.currentTarget.value);
                setHistoryId(null);
              },
              isRequired: true,
              rows: 8,
            },
            label: {
              fontFamily: '',
            },
          }}
        />
        <ContextControllers
          breakpoint={breakpoint}
          overrides={{
            ContextControllers: {
              width: 'unset',
            },
            TextAreaField: {
              label: 'Instructions',
              onChange: (e) => {
                setPrompt(e.currentTarget.value);
                setHistoryId(null);
              },
              ...(['large', 'medium'].includes(breakpoint) ? { rows: 12 } : {}),
            },
            SelectField37472723: {
              options: vocabularyOptions,
              placeholder: '',
              value: vocabulary,
              onChange: (e) => {
                setVocabulary(e.target.value);
                setHistoryId(null);
              },
            },
            SelectField37472714: {
              options: writeTypeOptions,
              placeholder: '',
              value: writeType,
              onChange: (e) => {
                setWriteType(e.target.value);
                setHistoryId(null);
              },
            },
            SelectField37472732: {
              options: wordsCountOptions,
              placeholder: '',
              value: wordsCount,
              onChange: (e) => {
                setWordsCount(e.target.value);
                setHistoryId(null);
              },
            },
            Button: {
              onClick: reviewClick,
              isLoading: isReviewLoading,
            },
          }}
        />
        <Reviews
          breakpoint={breakpoint}
          display={reviewed ? 'flex' : 'none'}
          overrides={{
            Reviews: {
              width: 'unset',
            },
            ReviewCard39025281: {
              overrides: {
                label: {
                  fontFamily: '',
                  children: 'Content',
                },
                TextAreaField: {
                  isDisabled: true,
                  placeholder: '',
                  // defaultValue: '',
                  value: contentReview.review,
                },
                Rating: {
                  value: contentReview.score,
                },
              },
            },
            ReviewCard39025335: {
              overrides: {
                label: {
                  fontFamily: '',
                  children: 'Structure',
                },
                TextAreaField: {
                  isDisabled: true,
                  placeholder: '',
                  // defaultValue: '',
                  value: structureReview.review,
                },
                Rating: {
                  value: structureReview.score,
                },
              },
            },

            ReviewCard39025357: {
              overrides: {
                label: {
                  fontFamily: '',
                  children: 'Grammar',
                },
                TextAreaField: {
                  isDisabled: true,
                  placeholder: '',
                  // defaultValue: '',
                  value: grammarReview.review,
                },
                Rating: {
                  value: grammarReview.score,
                },
              },
            },
            ReviewCard39025381: {
              overrides: {
                label: {
                  fontFamily: '',
                  children: 'Style',
                },
                TextAreaField: {
                  isDisabled: true,
                  placeholder: '',
                  // defaultValue: '',
                  value: styleReview.review,
                },
                Rating: {
                  value: styleReview.score,
                },
              },
            },
            ReviewCard39025403: {
              overrides: {
                label: {
                  fontFamily: '',
                  children: 'Purpose',
                },
                TextAreaField: {
                  isDisabled: true,
                  placeholder: '',
                  // defaultValue: '',
                  value: purposeReview.review,
                },
                Rating: {
                  value: purposeReview.score,
                },
              },
            },
            ReviewCard39025425: {
              overrides: {
                label: {
                  fontFamily: '',
                  children: 'Vocabulary',
                },
                TextAreaField: {
                  isDisabled: true,
                  placeholder: '',
                  // defaultValue: '',
                  value: vocabularyReview.review,
                },
                Rating: {
                  value: vocabularyReview.score,
                },
              },
            },
          }}
        />

        <RewriteControllers
          breakpoint={breakpoint}
          overrides={{
            RewriteControllers: {
              width: 'unset',
            },
            Button37513015: {
              onClick: () => rewriteClick('sentence'),
              isLoading: isRewriteLoading,
              ...(['large', 'medium', 'small', 'base'].includes(breakpoint)
                ? { fontSize: '16px' }
                : {}),
            },
            Button37513004: {
              onClick: () => rewriteClick('paragraph'),
              isLoading: isRewriteLoading,
              ...(['large', 'medium', 'small', 'base'].includes(breakpoint)
                ? { fontSize: '16px' }
                : {}),
            },
            Button37513011: {
              onClick: () => rewriteClick(''),
              isLoading: isRewriteLoading,
              ...(['large', 'medium', 'small', 'base'].includes(breakpoint)
                ? { fontSize: '16px' }
                : {}),
            },
          }}
        />
        {rewritten && rewriteCards()}
      </Flex>

      <MessageBox
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        msgHeading={msgHeading}
        msgContent={msgContent}
      />
    </View>
  );
}
