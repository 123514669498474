/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Button, Flex } from "@aws-amplify/ui-react";
export default function RewriteControllers(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        Button37513015: {},
        Button37513004: {},
        Button37513011: {},
        RewriteControllers: {},
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        Button37513015: { shrink: "0", alignSelf: "stretch" },
        Button37513004: { shrink: "0", alignSelf: "stretch" },
        Button37513011: { shrink: "0", alignSelf: "stretch" },
        RewriteControllers: {
          direction: "column",
          width: "480px",
          height: "unset",
          justifyContent: "flex-start",
          alignItems: "center",
        },
      },
      variantValues: { breakpoint: "small" },
    },
    {
      overrides: {
        Button37513015: { shrink: "0", alignSelf: "stretch" },
        Button37513004: { shrink: "0", alignSelf: "stretch" },
        Button37513011: { shrink: "0", alignSelf: "stretch" },
        RewriteControllers: {
          direction: "column",
          width: "480px",
          height: "unset",
          justifyContent: "flex-start",
          alignItems: "center",
        },
      },
      variantValues: { breakpoint: "base" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="20px"
      direction="row"
      width="1440px"
      height="84px"
      justifyContent="center"
      alignItems="flex-start"
      overflow="hidden"
      position="relative"
      padding="12px 24px 12px 24px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "RewriteControllers")}
    >
      <Button
        width="unset"
        height="60px"
        grow="1"
        shrink="1"
        basis="0"
        size="large"
        isDisabled={false}
        variation="primary"
        children="Rewrite By Sentence (1 credit)"
        {...getOverrideProps(overrides, "Button37513015")}
      ></Button>
      <Button
        width="unset"
        height="60px"
        grow="1"
        shrink="1"
        basis="0"
        size="large"
        isDisabled={false}
        variation="primary"
        children="Rewrite By Paragraph (1 credit)"
        {...getOverrideProps(overrides, "Button37513004")}
      ></Button>
      <Button
        width="unset"
        height="60px"
        grow="1"
        shrink="1"
        basis="0"
        size="large"
        isDisabled={false}
        variation="primary"
        children="Creative Rewrite ( 1 credit)"
        {...getOverrideProps(overrides, "Button37513011")}
      ></Button>
    </Flex>
  );
}
