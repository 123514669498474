import avatar1 from '../images/avatar1.svg';
import avatar2 from '../images/avatar2.svg';
import avatar3 from '../images/avatar3.svg';
import avatar4 from '../images/avatar4.svg';
import avatar5 from '../images/avatar5.svg';
import avatar6 from '../images/avatar6.svg';
import avatar7 from '../images/avatar7.svg';
import avatar8 from '../images/avatar8.svg';

const imageMapping = {
  1: avatar1,
  2: avatar2,
  3: avatar3,
  4: avatar4,
  5: avatar5,
  6: avatar6,
  7: avatar7,
  8: avatar8,
};

export {
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
  avatar6,
  avatar7,
  avatar8,
  imageMapping,
};
