import { useState, useEffect, useCallback } from 'react';

import { useAuthenticator } from '@aws-amplify/ui-react';
import { getApi, postApi } from './utils';

export const useUserProfile = (initProfile = {}) => {
  const { route } = useAuthenticator((context) => [context.route]);

  const [profile, setProfile] = useState(initProfile);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getProfile = async (route) => {
    if (route === 'authenticated') {
      setIsLoading(true);
      await getApi(
        '/api/profile',
        {},
        (err) => {
          setError('Get profile error: ' + err);
        },
        (data) => {
          setProfile(data);
        },
      );
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getProfile(route);
    return () => {};
  }, [route]);

  const refreshProfile = useCallback(
    async ()=>{
      getProfile(route);
    }, [route]
  );

  const updateProfile = useCallback(
    async (newProfile, onSuccess=()=>{}) => {
      setIsLoading(true);
      console.log({ newProfile });
      //   newProfile.preventDefault();

      if (
        newProfile.avatar === profile.avatar &&
        newProfile.name === profile.name
      ) {
        // unchanged
        setIsLoading(false);
        return;
      }

      //   const exercise = {
      //     avatar: newProfile.avatar,
      //     name: newProfile.name,
      //   };

      //   // if any fields are empty, display an error
      //   const emptyFields = Object.keys(exercise).some(
      //     (field) => !newProfile[field],
      //   );

      //   if (emptyFields) {
      //     setError('Emptry profile fields');
      //     return;
      //   }
      if (!newProfile.avatar) {
        setError('Empty avatar field');
        setIsLoading(false);
        return;
      }

      await postApi(
        '/api/profile',
        newProfile,
        (err) => {
          setError('Post profile error: ' + err);
        },
        (data) => {
          setProfile({
            ...profile,
            avatar: newProfile.avatar,
            name: newProfile.name,
          });
          setIsLoading(false);
          onSuccess()
        },
      );
    },
    [profile],
  );

  const deductCredit = useCallback(
    (consumed) => {
      const newCredit =
        profile.credits - consumed > 0 ? profile.credits - consumed : 0;
      console.log({ newCredit, profile, consumed });
      setProfile({ ...profile, credits: newCredit });
    },
    [profile],
  );

  const purchaseCredit = useCallback(
    (purchased) => {
      const newCredit = profile.credits + purchased;
      console.log({ newCredit, profile });
      setProfile({ ...profile, credits: newCredit });
    },
    [profile],
  );

  return {
    profile,
    isLoading,
    error,
    deductCredit,
    purchaseCredit,
    updateProfile,
    refreshProfile
  };
};
