/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import {
  Button,
  Divider,
  Flex,
  Image,
  Radio,
  Text,
  TextField,
} from "@aws-amplify/ui-react";
export default function EditProfileUI(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="16px"
      direction="column"
      width="640px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "EditProfileUI")}
    >
      <Flex
        gap="24px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="24px 24px 24px 24px"
        {...getOverrideProps(overrides, "Content")}
      >
        <Divider
          width="unset"
          height="1px"
          shrink="0"
          alignSelf="stretch"
          size="small"
          orientation="horizontal"
          {...getOverrideProps(overrides, "Divider38243437")}
        ></Divider>
        <Flex
          gap="16px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Forms")}
        >
          <Flex
            gap="16px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Profile")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(13,26,38,1)"
              lineHeight="22px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              textDecoration="underline"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Select Avatar"
              {...getOverrideProps(overrides, "Select Avatar")}
            ></Text>
            <Flex
              gap="18px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              overflow="hidden"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(255,255,255,1)"
              {...getOverrideProps(overrides, "Frame 434")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="6px 6px 6px 6px"
                backgroundColor="rgba(255,255,255,1)"
                {...getOverrideProps(overrides, "Frame 43538243594")}
              >
                <Image
                  width="96px"
                  height="96px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  borderRadius="160px"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  {...getOverrideProps(overrides, "image38243439")}
                ></Image>
                <Radio
                  width="unset"
                  height="unset"
                  shrink="0"
                  size="large"
                  defaultChecked={true}
                  isDisabled={false}
                  labelPosition="start"
                  {...getOverrideProps(overrides, "Radio38243518")}
                ></Radio>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="6px 6px 6px 6px"
                backgroundColor="rgba(255,255,255,1)"
                {...getOverrideProps(overrides, "Frame 43638243597")}
              >
                <Image
                  width="96px"
                  height="96px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  borderRadius="160px"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  {...getOverrideProps(overrides, "image38243598")}
                ></Image>
                <Radio
                  width="unset"
                  height="unset"
                  shrink="0"
                  size="large"
                  defaultChecked={true}
                  isDisabled={false}
                  labelPosition="start"
                  {...getOverrideProps(overrides, "Radio38243599")}
                ></Radio>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="6px 6px 6px 6px"
                backgroundColor="rgba(255,255,255,1)"
                {...getOverrideProps(overrides, "Frame 43738243605")}
              >
                <Image
                  width="96px"
                  height="96px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  borderRadius="160px"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  {...getOverrideProps(overrides, "image38243606")}
                ></Image>
                <Radio
                  width="unset"
                  height="unset"
                  shrink="0"
                  size="large"
                  defaultChecked={true}
                  isDisabled={false}
                  labelPosition="start"
                  {...getOverrideProps(overrides, "Radio38243607")}
                ></Radio>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="6px 6px 6px 6px"
                backgroundColor="rgba(255,255,255,1)"
                {...getOverrideProps(overrides, "Frame 43838243613")}
              >
                <Image
                  width="96px"
                  height="96px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  borderRadius="160px"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  {...getOverrideProps(overrides, "image38243614")}
                ></Image>
                <Radio
                  width="unset"
                  height="unset"
                  shrink="0"
                  size="large"
                  defaultChecked={true}
                  isDisabled={false}
                  labelPosition="start"
                  {...getOverrideProps(overrides, "Radio38243615")}
                ></Radio>
              </Flex>
            </Flex>
            <Flex
              gap="18px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              overflow="hidden"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(255,255,255,1)"
              {...getOverrideProps(overrides, "Frame 43538243621")}
            >
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="6px 6px 6px 6px"
                backgroundColor="rgba(255,255,255,1)"
                {...getOverrideProps(overrides, "Frame 43538243622")}
              >
                <Image
                  width="96px"
                  height="96px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  borderRadius="160px"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  {...getOverrideProps(overrides, "image38243623")}
                ></Image>
                <Radio
                  width="unset"
                  height="unset"
                  shrink="0"
                  size="large"
                  defaultChecked={true}
                  isDisabled={false}
                  labelPosition="start"
                  {...getOverrideProps(overrides, "Radio38243624")}
                ></Radio>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="6px 6px 6px 6px"
                backgroundColor="rgba(255,255,255,1)"
                {...getOverrideProps(overrides, "Frame 43638243625")}
              >
                <Image
                  width="96px"
                  height="96px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  borderRadius="160px"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  {...getOverrideProps(overrides, "image38243626")}
                ></Image>
                <Radio
                  width="unset"
                  height="unset"
                  shrink="0"
                  size="large"
                  defaultChecked={true}
                  isDisabled={false}
                  labelPosition="start"
                  {...getOverrideProps(overrides, "Radio38243627")}
                ></Radio>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="6px 6px 6px 6px"
                backgroundColor="rgba(255,255,255,1)"
                {...getOverrideProps(overrides, "Frame 43738243628")}
              >
                <Image
                  width="96px"
                  height="96px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  borderRadius="160px"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  {...getOverrideProps(overrides, "image38243629")}
                ></Image>
                <Radio
                  width="unset"
                  height="unset"
                  shrink="0"
                  size="large"
                  defaultChecked={true}
                  isDisabled={false}
                  labelPosition="start"
                  {...getOverrideProps(overrides, "Radio38243630")}
                ></Radio>
              </Flex>
              <Flex
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="6px 6px 6px 6px"
                backgroundColor="rgba(255,255,255,1)"
                {...getOverrideProps(overrides, "Frame 43838243631")}
              >
                <Image
                  width="96px"
                  height="96px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  borderRadius="160px"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  {...getOverrideProps(overrides, "image38243632")}
                ></Image>
                <Radio
                  width="unset"
                  height="unset"
                  shrink="0"
                  size="large"
                  defaultChecked={true}
                  isDisabled={false}
                  labelPosition="start"
                  {...getOverrideProps(overrides, "Radio38243633")}
                ></Radio>
              </Flex>
            </Flex>
          </Flex>
          <TextField
            width="unset"
            height="unset"
            label="Name"
            placeholder="John Doe"
            shrink="0"
            alignSelf="stretch"
            size="default"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            {...getOverrideProps(overrides, "TextField38243442")}
          ></TextField>
          <TextField
            width="unset"
            height="unset"
            label="Email"
            placeholder="john.doe@awsamplify.com"
            shrink="0"
            alignSelf="stretch"
            size="default"
            isDisabled={true}
            labelHidden={false}
            variation="default"
            {...getOverrideProps(overrides, "TextField38243444")}
          ></TextField>
          <Divider
            width="unset"
            height="1px"
            shrink="0"
            alignSelf="stretch"
            size="small"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider38243445")}
          ></Divider>
          <Flex
            gap="24px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            overflow="hidden"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "Frame 439")}
          >
            <Button
              width="100px"
              height="unset"
              shrink="0"
              size="default"
              isDisabled={false}
              variation="primary"
              children="Save"
              {...getOverrideProps(overrides, "Button38243679")}
            ></Button>
            <Button
              width="100px"
              height="unset"
              shrink="0"
              size="default"
              isDisabled={false}
              variation="primary"
              children="Cancel"
              {...getOverrideProps(overrides, "Button38243683")}
            ></Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
