/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import {
  Button,
  Flex,
  SelectField,
  TextAreaField,
} from "@aws-amplify/ui-react";
export default function ContextControllers(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        TextAreaField: {},
        SelectField37472732: {},
        SelectField37472714: {},
        SelectField37472723: {},
        "Frame 447": {},
        Button: {},
        "Frame 445": {},
        "Frame 419": {},
        ContextControllers: {},
      },
      variantValues: { breakpoint: "xl" },
    },
    {
      overrides: {
        TextAreaField: { shrink: "1", grow: "1", basis: "0" },
        SelectField37472732: { width: "300px" },
        SelectField37472714: { width: "300px" },
        SelectField37472723: {},
        "Frame 447": { direction: "column" },
        Button: { alignSelf: "stretch" },
        "Frame 445": { shrink: "0", alignSelf: "stretch" },
        "Frame 419": {
          gap: "20px",
          direction: "column",
          justifyContent: "flex-end",
          alignItems: "flex-start",
        },
        ContextControllers: {
          direction: "row",
          width: "992px",
          justifyContent: "flex-start",
          alignItems: "center",
        },
      },
      variantValues: { breakpoint: "large" },
    },
    {
      overrides: {
        TextAreaField: { shrink: "1", grow: "1", basis: "0" },
        SelectField37472732: { width: "300px" },
        SelectField37472714: { width: "300px" },
        SelectField37472723: {},
        "Frame 447": { direction: "column" },
        Button: { alignSelf: "stretch" },
        "Frame 445": { shrink: "0", alignSelf: "stretch" },
        "Frame 419": {
          gap: "20px",
          direction: "column",
          justifyContent: "flex-end",
          alignItems: "flex-start",
        },
        ContextControllers: {
          direction: "row",
          width: "768px",
          justifyContent: "flex-start",
          alignItems: "center",
        },
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        TextAreaField: {},
        SelectField37472732: { width: "unset", alignSelf: "stretch" },
        SelectField37472714: { width: "unset", alignSelf: "stretch" },
        SelectField37472723: { width: "unset", alignSelf: "stretch" },
        "Frame 447": { direction: "column", alignSelf: "stretch" },
        Button: { alignSelf: "stretch" },
        "Frame 445": { alignSelf: "stretch" },
        "Frame 419": {
          gap: "20px",
          direction: "column",
          justifyContent: "flex-end",
          alignItems: "flex-start",
          shrink: "1",
          grow: "1",
          basis: "0",
        },
        ContextControllers: { width: "480px", height: "520px" },
      },
      variantValues: { breakpoint: "small" },
    },
    {
      overrides: {
        TextAreaField: {},
        SelectField37472732: { width: "unset", alignSelf: "stretch" },
        SelectField37472714: { width: "unset", alignSelf: "stretch" },
        SelectField37472723: { width: "unset", alignSelf: "stretch" },
        "Frame 447": { direction: "column", alignSelf: "stretch" },
        Button: { alignSelf: "stretch" },
        "Frame 445": { alignSelf: "stretch" },
        "Frame 419": {
          gap: "20px",
          direction: "column",
          justifyContent: "flex-end",
          alignItems: "flex-start",
          shrink: "1",
          grow: "1",
          basis: "0",
        },
        ContextControllers: { width: "480px", height: "520px" },
      },
      variantValues: { breakpoint: "base" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="20px"
      direction="column"
      width="1440px"
      height="unset"
      justifyContent="center"
      alignItems="flex-start"
      overflow="hidden"
      position="relative"
      padding="12px 24px 12px 24px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "ContextControllers")}
    >
      <TextAreaField
        width="unset"
        height="unset"
        label="Instructions"
        placeholder=" "
        shrink="0"
        alignSelf="stretch"
        size="default"
        isDisabled={false}
        labelHidden={false}
        variation="default"
        {...getOverrideProps(overrides, "TextAreaField")}
      ></TextAreaField>
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-end"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(255,255,255,1)"
        display="flex"
        {...getOverrideProps(overrides, "Frame 419")}
      >
        <Flex
          gap="20px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="1px 1px 1px 1px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 447")}
        >
          <SelectField
            width="260px"
            height="unset"
            label="Words Count"
            shrink="0"
            placeholder="Placeholder"
            size="default"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            {...getOverrideProps(overrides, "SelectField37472732")}
          ></SelectField>
          <SelectField
            width="260px"
            height="unset"
            label="Writing Types"
            shrink="0"
            placeholder="Placeholder"
            size="default"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            {...getOverrideProps(overrides, "SelectField37472714")}
          ></SelectField>
          <SelectField
            width="300px"
            height="unset"
            label="Vocabulary"
            shrink="0"
            placeholder="Placeholder"
            size="default"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            {...getOverrideProps(overrides, "SelectField37472723")}
          ></SelectField>
        </Flex>
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-end"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 445")}
        >
          <Button
            width="unset"
            height="60px"
            overflow="hidden"
            shrink="0"
            size="large"
            isDisabled={false}
            variation="primary"
            children="Review  (1 credit)"
            {...getOverrideProps(overrides, "Button")}
          ></Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
