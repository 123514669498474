import { View, Flex, useBreakpointValue } from '@aws-amplify/ui-react';
import {} from '../ui-components';
export function TermCondition() {
  const breakpoint = useBreakpointValue({
    base: 'base',
    small: 'small',
    medium: 'medium',
    large: 'large',
    xl: 'xl',
    xxl: 'xxl',
  });
  console.log({ breakpoint });

  return (
    <View className="App-header">
      <Flex
        direction="column"
        style={{
          width: breakpoint === 'xxl' ? 1480 : '90%',

          paddingTop: 12,
          paddingBottom: 12,
        }}
      >
        <Flex
          direction="column"
          style={{ padding: 24, backgroundColor: 'rgba(255,255,255,1)' }}
        >
          <h1>Terms and conditions</h1>
          <p>
            These terms and conditions (&#8220;Agreement&#8221;) set forth the
            general terms and conditions of your use of the{' '}
            <a href="https://www.writingbooster.com">writingbooster.com</a>{' '}
            website (&#8220;Website&#8221; or &#8220;Service&#8221;) and any of
            its related products and services (collectively,
            &#8220;Services&#8221;). This Agreement is legally binding between
            you (&#8220;User&#8221;, &#8220;you&#8221; or &#8220;your&#8221;)
            and Hundred Technology Pty Ltd (&#8220;Hundred Technology Pty
            Ltd&#8221;, &#8220;we&#8221;, &#8220;us&#8221; or
            &#8220;our&#8221;). If you are entering into this agreement on
            behalf of a business or other legal entity, you represent that you
            have the authority to bind such entity to this agreement, in which
            case the terms &#8220;User&#8221;, &#8220;you&#8221; or
            &#8220;your&#8221; shall refer to such entity. If you do not have
            such authority, or if you do not agree with the terms of this
            agreement, you must not accept this agreement and may not access and
            use the Website and Services. By accessing and using the Website and
            Services, you acknowledge that you have read, understood, and agree
            to be bound by the terms of this Agreement. You acknowledge that
            this Agreement is a contract between you and Hundred Technology Pty
            Ltd, even though it is electronic and is not physically signed by
            you, and it governs your use of the Website and Services.
          </p>
          <div class="wpembed-toc">
            <h3>Table of contents</h3>
            <ol class="wpembed-toc">
              <li>
                <a href="#accounts-and-membership">Accounts and membership</a>
              </li>
              <li>
                <a href="#links-to-other-resources">Links to other resources</a>
              </li>
              <li>
                <a href="#prohibited-uses">Prohibited uses</a>
              </li>
              <li>
                <a href="#intellectual-property-rights">
                  Intellectual property rights
                </a>
              </li>
              <li>
                <a href="#limitation-of-liability">Limitation of liability</a>
              </li>
              <li>
                <a href="#indemnification">Indemnification</a>
              </li>
              <li>
                <a href="#severability">Severability</a>
              </li>
              <li>
                <a href="#dispute-resolution">Dispute resolution</a>
              </li>
              <li>
                <a href="#changes-and-amendments">Changes and amendments</a>
              </li>
              <li>
                <a href="#acceptance-of-these-terms">
                  Acceptance of these terms
                </a>
              </li>
              <li>
                <a href="#contacting-us">Contacting us</a>
              </li>
            </ol>
          </div>
          <h2 id="accounts-and-membership">Accounts and membership</h2>
          <p>
            If you create an account on the Website, you are responsible for
            maintaining the security of your account and you are fully
            responsible for all activities that occur under the account and any
            other actions taken in connection with it. We may, but have no
            obligation to, monitor and review new accounts before you may sign
            in and start using the Services. Providing false contact information
            of any kind may result in the termination of your account. You must
            immediately notify us of any unauthorized uses of your account or
            any other breaches of security. We will not be liable for any acts
            or omissions by you, including any damages of any kind incurred as a
            result of such acts or omissions. We may suspend, disable, or delete
            your account (or any part thereof) if we determine that you have
            violated any provision of this Agreement or that your conduct or
            content would tend to damage our reputation and goodwill. If we
            delete your account for the foregoing reasons, you may not
            re-register for our Services. We may block your email address and
            Internet protocol address to prevent further registration.
          </p>
          <h2 id="links-to-other-resources">Links to other resources</h2>
          <p>
            Although the Website and Services may link to other resources (such
            as websites, mobile applications, etc.), we are not, directly or
            indirectly, implying any approval, association, sponsorship,
            endorsement, or affiliation with any linked resource, unless
            specifically stated herein. We are not responsible for examining or
            evaluating, and we do not warrant the offerings of, any businesses
            or individuals or the content of their resources. We do not assume
            any responsibility or liability for the actions, products, services,
            and content of any other third parties. You should carefully review
            the legal statements and other conditions of use of any resource
            which you access through a link on the Website. Your linking to any
            other off-site resources is at your own risk.
          </p>
          <h2 id="prohibited-uses">Prohibited uses</h2>
          <p>
            In addition to other terms as set forth in the Agreement, you are
            prohibited from using the Website and Services or Content: (a) for
            any unlawful purpose; (b) to solicit others to perform or
            participate in any unlawful acts; (c) to violate any international,
            federal, provincial or state regulations, rules, laws, or local
            ordinances; (d) to infringe upon or violate our intellectual
            property rights or the intellectual property rights of others; (e)
            to harass, abuse, insult, harm, defame, slander, disparage,
            intimidate, or discriminate based on gender, sexual orientation,
            religion, ethnicity, race, age, national origin, or disability; (f)
            to submit false or misleading information; (g) to upload or transmit
            viruses or any other type of malicious code that will or may be used
            in any way that will affect the functionality or operation of the
            Website and Services, third party products and services, or the
            Internet; (h) to spam, phish, pharm, pretext, spider, crawl, or
            scrape; (i) for any obscene or immoral purpose; or (j) to interfere
            with or circumvent the security features of the Website and
            Services, third party products and services, or the Internet. We
            reserve the right to terminate your use of the Website and Services
            for violating any of the prohibited uses.
          </p>
          <h2 id="intellectual-property-rights">
            Intellectual property rights
          </h2>
          <p>
            &#8220;Intellectual Property Rights&#8221; means all present and
            future rights conferred by statute, common law or equity in or in
            relation to any copyright and related rights, trademarks, designs,
            patents, inventions, goodwill and the right to sue for passing off,
            rights to inventions, rights to use, and all other intellectual
            property rights, in each case whether registered or unregistered and
            including all applications and rights to apply for and be granted,
            rights to claim priority from, such rights and all similar or
            equivalent rights or forms of protection and any other results of
            intellectual activity which subsist or will subsist now or in the
            future in any part of the world. This Agreement does not transfer to
            you any intellectual property owned by Hundred Technology Pty Ltd or
            third parties, and all rights, titles, and interests in and to such
            property will remain (as between the parties) solely with Hundred
            Technology Pty Ltd. All trademarks, service marks, graphics and
            logos used in connection with the Website and Services, are
            trademarks or registered trademarks of Hundred Technology Pty Ltd or
            its licensors. Other trademarks, service marks, graphics and logos
            used in connection with the Website and Services may be the
            trademarks of other third parties. Your use of the Website and
            Services grants you no right or license to reproduce or otherwise
            use any of Hundred Technology Pty Ltd or third party trademarks.
          </p>
          <h2 id="limitation-of-liability">Limitation of liability</h2>
          <p>
            To the fullest extent permitted by applicable law, in no event will
            Hundred Technology Pty Ltd, its affiliates, directors, officers,
            employees, agents, suppliers or licensors be liable to any person
            for any indirect, incidental, special, punitive, cover or
            consequential damages (including, without limitation, damages for
            lost profits, revenue, sales, goodwill, use of content, impact on
            business, business interruption, loss of anticipated savings, loss
            of business opportunity) however caused, under any theory of
            liability, including, without limitation, contract, tort, warranty,
            breach of statutory duty, negligence or otherwise, even if the
            liable party has been advised as to the possibility of such damages
            or could have foreseen such damages. To the maximum extent permitted
            by applicable law, the aggregate liability of Hundred Technology Pty
            Ltd and its affiliates, officers, employees, agents, suppliers and
            licensors relating to the services will be limited to an amount no
            greater than one dollar or any amounts actually paid in cash by you
            to Hundred Technology Pty Ltd for the prior one month period prior
            to the first event or occurrence giving rise to such liability. The
            limitations and exclusions also apply if this remedy does not fully
            compensate you for any losses or fails of its essential purpose.
          </p>
          <h2 id="indemnification">Indemnification</h2>
          <p>
            You agree to indemnify and hold Hundred Technology Pty Ltd and its
            affiliates, directors, officers, employees, agents, suppliers and
            licensors harmless from and against any liabilities, losses, damages
            or costs, including reasonable attorneys&#8217; fees, incurred in
            connection with or arising from any third party allegations, claims,
            actions, disputes, or demands asserted against any of them as a
            result of or relating to your Content, your use of the Website and
            Services or any willful misconduct on your part.
          </p>
          <h2 id="refund policy">Refund Policy</h2>
          <p>
            Since the Website offers non-tangible, irrevocable goods we do not
            provide refunds after the product is purchased, which you
            acknowledge prior to purchasing any product on the Website. Please
            make sure that you&#8217;ve carefully read service description
            before making a purchase.
          </p>
          <h2 id="severability">Severability</h2>
          <p>
            All rights and restrictions contained in this Agreement may be
            exercised and shall be applicable and binding only to the extent
            that they do not violate any applicable laws and are intended to be
            limited to the extent necessary so that they will not render this
            Agreement illegal, invalid or unenforceable. If any provision or
            portion of any provision of this Agreement shall be held to be
            illegal, invalid or unenforceable by a court of competent
            jurisdiction, it is the intention of the parties that the remaining
            provisions or portions thereof shall constitute their agreement with
            respect to the subject matter hereof, and all such remaining
            provisions or portions thereof shall remain in full force and
            effect.
          </p>
          <h2 id="dispute-resolution">Dispute resolution</h2>
          <p>
            The formation, interpretation, and performance of this Agreement and
            any disputes arising out of it shall be governed by the substantive
            and procedural laws of New South Wales, Australia without regard to
            its rules on conflicts or choice of law and, to the extent
            applicable, the laws of Australia. The exclusive jurisdiction and
            venue for actions related to the subject matter hereof shall be the
            courts located in New South Wales, Australia, and you hereby submit
            to the personal jurisdiction of such courts. You hereby waive any
            right to a jury trial in any proceeding arising out of or related to
            this Agreement. The United Nations Convention on Contracts for the
            International Sale of Goods does not apply to this Agreement.
          </p>
          <h2 id="changes-and-amendments">Changes and amendments</h2>
          <p>
            We reserve the right to modify this Agreement or its terms related
            to the Website and Services at any time at our discretion. When we
            do, we will revise the updated date at the bottom of this page. We
            may also provide notice to you in other ways at our discretion, such
            as through the contact information you have provided.
          </p>
          <p>
            An updated version of this Agreement will be effective immediately
            upon the posting of the revised Agreement unless otherwise
            specified. Your continued use of the Website and Services after the
            effective date of the revised Agreement (or such other act specified
            at that time) will constitute your consent to those changes.
          </p>
          <h2 id="acceptance-of-these-terms">Acceptance of these terms</h2>
          <p>
            You acknowledge that you have read this Agreement and agree to all
            its terms and conditions. By accessing and using the Website and
            Services you agree to be bound by this Agreement. If you do not
            agree to abide by the terms of this Agreement, you are not
            authorized to access or use the Website and Services. This terms and
            conditions policy was created with the help of{' '}
            <a
              href="https://www.websitepolicies.com/terms-and-conditions-generator"
              target="_blank"
            >
              WebsitePolicies
            </a>
            .
          </p>
          <h2 id="contacting-us">Contacting us</h2>
          <p>
            If you have any questions, concerns, or complaints regarding this
            Agreement, we encourage you to contact us using the details below:
          </p>
          <p>
            <a href="&#109;&#097;&#105;&#108;&#116;&#111;&#058;&#119;riti&#110;gb&#111;&#111;s&#116;er&#46;cu&#115;t&#111;&#109;e&#114;s&#101;r&#118;ice&#64;g&#109;&#97;il.c&#111;&#109;">
              &#119;ri&#116;i&#110;g&#98;&#111;&#111;st&#101;&#114;.c&#117;&#115;&#116;&#111;&#109;er&#115;&#101;r&#118;&#105;&#99;e&#64;g&#109;a&#105;&#108;.co&#109;
            </a>
            &nbsp;or&nbsp;
            <a href="mailto:writingbooster.customerservice1@gmail.com">
              writingbooster.customerservice1@gmail.com
            </a>
          </p>
          <p>This document was last updated on April 28, 2023</p>
        </Flex>
      </Flex>
    </View>
  );
}
