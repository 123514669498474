import { useState } from 'react';
import { View, Flex, Loader, useBreakpointValue } from '@aws-amplify/ui-react';
import { HistoryCard } from '../components/HistoryCard';
import { useHistory } from '../hookers/useHistory';

export function ViewHistory() {
  const { history, error, isLoading, getHistory } = useHistory();
  const { Count, Items, LastEvaluatedKey, ScannedCount } = history || {};
  const breakpoint = useBreakpointValue({
    base: 'base',
    small: 'small',
    medium: 'medium',
    large: 'large',
    xl: 'xl',
    xxl: 'xxl',
  });
  // console.log({ breakpoint });
  return (
    <View className="App-header">
      <View
        direction="column"
        style={{
          paddingTop: 12,
          paddingBottom: 12,
          width: breakpoint === 'xxl' ? 1480 : '90%',
        }}
      >
        {isLoading && <Loader />}
        {!isLoading &&
          Items &&
          Items.map((historyItem, index) => (
            <View key={`history-${index}`}>
              <HistoryCard
                breakpoint={breakpoint}
                historyItem={historyItem}
              ></HistoryCard>
            </View>
          ))}
      </View>
    </View>
  );
}
