/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import {
  Button,
  Flex,
  SelectField,
  TextAreaField,
} from "@aws-amplify/ui-react";
export default function WritingSampleContext(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        SelectField38113342: {},
        SelectField38113341: {},
        SelectField38113340: {},
        "Frame 433": {},
        Button38113343: {},
        "Frame 446": {},
        "Frame 419": {},
        TextAreaField: {},
        Button38113378: {},
        "Frame 432": {},
        WritingSampleContext: {},
      },
      variantValues: { breakpoint: "xl" },
    },
    {
      overrides: {
        SelectField38113342: { width: "unset", alignSelf: "stretch" },
        SelectField38113341: { width: "unset", alignSelf: "stretch" },
        SelectField38113340: { width: "unset", alignSelf: "stretch" },
        "Frame 433": {
          direction: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        },
        Button38113343: {},
        "Frame 446": { height: "60px", shrink: "0", alignSelf: "stretch" },
        "Frame 419": { direction: "column" },
        TextAreaField: {},
        Button38113378: { width: "293px" },
        "Frame 432": { direction: "column" },
        WritingSampleContext: {
          direction: "row",
          width: "992px",
          height: "unset",
        },
      },
      variantValues: { breakpoint: "large" },
    },
    {
      overrides: {
        SelectField38113342: { width: "unset", alignSelf: "stretch" },
        SelectField38113341: { width: "unset", alignSelf: "stretch" },
        SelectField38113340: { width: "unset", alignSelf: "stretch" },
        "Frame 433": {
          direction: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        },
        Button38113343: {},
        "Frame 446": { height: "60px", shrink: "0", alignSelf: "stretch" },
        "Frame 419": { direction: "column" },
        TextAreaField: {},
        Button38113378: { width: "293px" },
        "Frame 432": { direction: "column" },
        WritingSampleContext: {
          direction: "row",
          width: "768px",
          height: "unset",
        },
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        SelectField38113342: { width: "unset", alignSelf: "stretch" },
        SelectField38113341: { width: "unset", alignSelf: "stretch" },
        SelectField38113340: { width: "unset", alignSelf: "stretch" },
        "Frame 433": {
          direction: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        },
        Button38113343: {},
        "Frame 446": { height: "unset", alignSelf: "stretch" },
        "Frame 419": { direction: "column", height: "338px" },
        TextAreaField: {},
        Button38113378: { alignSelf: "stretch" },
        "Frame 432": { direction: "column" },
        WritingSampleContext: {
          width: "480px",
          height: "724px",
          alignItems: "flex-start",
        },
      },
      variantValues: { breakpoint: "small" },
    },
    {
      overrides: {
        SelectField38113342: { width: "unset", alignSelf: "stretch" },
        SelectField38113341: { width: "unset", alignSelf: "stretch" },
        SelectField38113340: { width: "unset", alignSelf: "stretch" },
        "Frame 433": {
          direction: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        },
        Button38113343: {},
        "Frame 446": { height: "unset", alignSelf: "stretch" },
        "Frame 419": { direction: "column", height: "338px" },
        TextAreaField: {},
        Button38113378: { alignSelf: "stretch" },
        "Frame 432": { direction: "column" },
        WritingSampleContext: {
          width: "480px",
          height: "724px",
          alignItems: "flex-start",
        },
      },
      variantValues: { breakpoint: "base" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="24px"
      direction="column"
      width="1439px"
      height="292px"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="12px 24px 12px 24px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "WritingSampleContext")}
    >
      <Flex
        gap="20px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(255,255,255,1)"
        display="flex"
        {...getOverrideProps(overrides, "Frame 419")}
      >
        <Flex
          gap="20px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="flex-start"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="1px 1px 1px 1px"
          backgroundColor="rgba(255,255,255,1)"
          display="flex"
          {...getOverrideProps(overrides, "Frame 433")}
        >
          <SelectField
            width="300px"
            height="unset"
            label="Words Count"
            shrink="0"
            placeholder="Placeholder"
            size="default"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            {...getOverrideProps(overrides, "SelectField38113342")}
          ></SelectField>
          <SelectField
            width="260px"
            height="unset"
            label="Writing Types"
            shrink="0"
            placeholder="Placeholder"
            size="default"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            {...getOverrideProps(overrides, "SelectField38113341")}
          ></SelectField>
          <SelectField
            width="260px"
            height="unset"
            label="Vocabulary"
            shrink="0"
            placeholder="Placeholder"
            size="default"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            {...getOverrideProps(overrides, "SelectField38113340")}
          ></SelectField>
        </Flex>
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="74px"
          justifyContent="flex-end"
          alignItems="flex-end"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 446")}
        >
          <Button
            width="unset"
            height="60px"
            overflow="hidden"
            shrink="0"
            alignSelf="stretch"
            size="large"
            isDisabled={false}
            variation="primary"
            children="Generate Instruction (1 credit)"
            {...getOverrideProps(overrides, "Button38113343")}
          ></Button>
        </Flex>
      </Flex>
      <Flex
        gap="20px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-end"
        alignItems="flex-end"
        overflow="hidden"
        grow="1"
        shrink="1"
        basis="0"
        alignSelf="stretch"
        position="relative"
        padding="1px 1px 1px 1px"
        backgroundColor="rgba(255,255,255,1)"
        display="flex"
        {...getOverrideProps(overrides, "Frame 432")}
      >
        <TextAreaField
          width="unset"
          height="unset"
          label="Instructions"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          placeholder="Placeholder"
          size="default"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          {...getOverrideProps(overrides, "TextAreaField")}
        ></TextAreaField>
        <Button
          width="unset"
          height="60px"
          shrink="0"
          size="large"
          isDisabled={false}
          variation="primary"
          children="Generate Sample (1 credit) "
          {...getOverrideProps(overrides, "Button38113378")}
        ></Button>
      </Flex>
    </Flex>
  );
}
