import { createContext, useContext } from 'react';
import { useUserProfile } from './useUserProfile';

export const defaultProfile = { avatar: 1, credits: 0, name: '', email: '' };

export const ProfileContext = createContext(defaultProfile);
export const ProfileProvider = ({ children }) => {
  const {
    profile,
    isLoading,
    error,
    deductCredit,
    purchaseCredit,
    updateProfile,
    refreshProfile
  } = useUserProfile(defaultProfile);

  return (
    <ProfileContext.Provider
      value={{
        profile,
        isLoading,
        error,
        deductCredit,
        purchaseCredit,
        updateProfile,
        refreshProfile
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfileContext = () => useContext(ProfileContext);
