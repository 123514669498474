/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Button, Flex } from "@aws-amplify/ui-react";
export default function CreditsButtons(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        Button38073242: {},
        Button38063209: {},
        "Frame 451": {},
        Button38083264: {},
        Button38083268: {},
        "Frame 452": {},
        "Frame 429": {},
        Button38083247: {},
        Button38083272: {},
        "Frame 453": {},
        Button38083276: {},
        Button38083280: {},
        "Frame 454": {},
        "Frame 430": {},
        Button38083285: {},
        Button38083251: {},
        Button38083255: {},
        "Frame 431": {},
        Button38083260: {},
        Button38083289: {},
        "Frame 450": {},
        CreditsButtons: {},
      },
      variantValues: { breakpoint: "large" },
    },
    {
      overrides: {
        Button38073242: {},
        Button38063209: {},
        "Frame 451": { alignSelf: "stretch" },
        Button38083264: {},
        Button38083268: {},
        "Frame 452": { alignSelf: "stretch" },
        "Frame 429": {
          direction: "column",
          height: "unset",
          shrink: "1",
          padding: "9px 18px 9px 18px",
          grow: "1",
          basis: "0",
        },
        Button38083247: {},
        Button38083272: {},
        "Frame 453": { alignSelf: "stretch" },
        Button38083276: {},
        Button38083280: {},
        "Frame 454": { alignSelf: "stretch" },
        "Frame 430": {
          direction: "column",
          height: "unset",
          shrink: "1",
          padding: "9px 18px 9px 18px",
          grow: "1",
          basis: "0",
        },
        Button38083285: { height: "72px" },
        Button38083251: { height: "72px" },
        Button38083255: { height: "72px" },
        "Frame 431": { height: "unset", padding: "9px 18px 9px 18px" },
        Button38083260: {},
        Button38083289: {},
        "Frame 450": { height: "unset", padding: "9px 18px 9px 18px" },
        CreditsButtons: {
          width: "768px",
          height: "572px",
          padding: "15px 0px 15px 0px",
        },
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        Button38073242: { shrink: "0", alignSelf: "stretch" },
        Button38063209: { shrink: "0", alignSelf: "stretch" },
        "Frame 451": { direction: "column", alignSelf: "stretch" },
        Button38083264: { shrink: "0", alignSelf: "stretch" },
        Button38083268: { shrink: "0", alignSelf: "stretch" },
        "Frame 452": { direction: "column", alignSelf: "stretch" },
        "Frame 429": {
          direction: "column",
          height: "363px",
          padding: "9px 18px 9px 18px",
        },
        Button38083247: { shrink: "0", alignSelf: "stretch" },
        Button38083272: { shrink: "0", alignSelf: "stretch" },
        "Frame 453": { direction: "column", alignSelf: "stretch" },
        Button38083276: { shrink: "0", alignSelf: "stretch" },
        Button38083280: { shrink: "0", alignSelf: "stretch" },
        "Frame 454": { direction: "column", alignSelf: "stretch" },
        "Frame 430": {
          direction: "column",
          height: "363px",
          padding: "9px 18px 9px 18px",
        },
        Button38083285: { height: "72px", shrink: "0" },
        Button38083251: { height: "72px", shrink: "0" },
        Button38083255: { height: "72px", shrink: "0" },
        "Frame 431": {
          direction: "column",
          height: "unset",
          shrink: "1",
          padding: "9px 18px 9px 18px",
          grow: "1",
          basis: "0",
        },
        Button38083260: { shrink: "0", alignSelf: "stretch" },
        Button38083289: { shrink: "0", alignSelf: "stretch" },
        "Frame 450": {
          direction: "column",
          height: "181px",
          padding: "9px 18px 9px 18px",
        },
        CreditsButtons: {
          width: "480px",
          height: "1209px",
          padding: "15px 0px 15px 0px",
        },
      },
      variantValues: { breakpoint: "small" },
    },
    {
      overrides: {
        Button38073242: { shrink: "0", alignSelf: "stretch" },
        Button38063209: { shrink: "0", alignSelf: "stretch" },
        "Frame 451": { direction: "column", alignSelf: "stretch" },
        Button38083264: { shrink: "0", alignSelf: "stretch" },
        Button38083268: { shrink: "0", alignSelf: "stretch" },
        "Frame 452": { direction: "column", alignSelf: "stretch" },
        "Frame 429": {
          direction: "column",
          height: "363px",
          padding: "9px 18px 9px 18px",
        },
        Button38083247: { shrink: "0", alignSelf: "stretch" },
        Button38083272: { shrink: "0", alignSelf: "stretch" },
        "Frame 453": { direction: "column", alignSelf: "stretch" },
        Button38083276: { shrink: "0", alignSelf: "stretch" },
        Button38083280: { shrink: "0", alignSelf: "stretch" },
        "Frame 454": { direction: "column", alignSelf: "stretch" },
        "Frame 430": {
          direction: "column",
          height: "363px",
          padding: "9px 18px 9px 18px",
        },
        Button38083285: { height: "72px", shrink: "0" },
        Button38083251: { height: "72px", shrink: "0" },
        Button38083255: { height: "72px", shrink: "0" },
        "Frame 431": {
          direction: "column",
          height: "272px",
          padding: "9px 18px 9px 18px",
        },
        Button38083260: { shrink: "0", alignSelf: "stretch" },
        Button38083289: { shrink: "0", alignSelf: "stretch" },
        "Frame 450": {
          direction: "column",
          height: "181px",
          padding: "9px 18px 9px 18px",
        },
        CreditsButtons: {
          width: "480px",
          height: "1209px",
          padding: "15px 0px 15px 0px",
        },
      },
      variantValues: { breakpoint: "base" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="0"
      direction="column"
      width="1440px"
      height="465px"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "CreditsButtons")}
    >
      <Flex
        gap="19px"
        direction="row"
        width="unset"
        height="120px"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="24px 18px 24px 18px"
        backgroundColor="rgba(255,255,255,1)"
        display="flex"
        {...getOverrideProps(overrides, "Frame 429")}
      >
        <Flex
          gap="19px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 451")}
        >
          <Button
            width="unset"
            height="72px"
            grow="1"
            shrink="1"
            basis="0"
            size="large"
            isDisabled={false}
            variation="primary"
            children="Buy 10 credits - $10"
            {...getOverrideProps(overrides, "Button38073242")}
          ></Button>
          <Button
            width="unset"
            height="72px"
            grow="1"
            shrink="1"
            basis="0"
            size="large"
            isDisabled={false}
            variation="primary"
            children="Buy 20 credits - $18"
            {...getOverrideProps(overrides, "Button38063209")}
          ></Button>
        </Flex>
        <Flex
          gap="19px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 452")}
        >
          <Button
            width="unset"
            height="72px"
            grow="1"
            shrink="1"
            basis="0"
            size="large"
            isDisabled={false}
            variation="primary"
            children="Buy 30 credits - $27"
            {...getOverrideProps(overrides, "Button38083264")}
          ></Button>
          <Button
            width="unset"
            height="72px"
            grow="1"
            shrink="1"
            basis="0"
            size="large"
            isDisabled={false}
            variation="primary"
            children="Buy 40 credits - $36"
            {...getOverrideProps(overrides, "Button38083268")}
          ></Button>
        </Flex>
      </Flex>
      <Flex
        gap="19px"
        direction="row"
        width="unset"
        height="120px"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="24px 18px 24px 18px"
        backgroundColor="rgba(255,255,255,1)"
        display="flex"
        {...getOverrideProps(overrides, "Frame 430")}
      >
        <Flex
          gap="19px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 453")}
        >
          <Button
            width="unset"
            height="72px"
            grow="1"
            shrink="1"
            basis="0"
            size="large"
            isDisabled={false}
            variation="primary"
            children="Buy 50 credits - $45"
            {...getOverrideProps(overrides, "Button38083247")}
          ></Button>
          <Button
            width="unset"
            height="72px"
            grow="1"
            shrink="1"
            basis="0"
            size="large"
            isDisabled={false}
            variation="primary"
            children="Buy 60 credits - $52"
            {...getOverrideProps(overrides, "Button38083272")}
          ></Button>
        </Flex>
        <Flex
          gap="19px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 454")}
        >
          <Button
            width="unset"
            height="72px"
            grow="1"
            shrink="1"
            basis="0"
            size="large"
            isDisabled={false}
            variation="primary"
            children="Buy 70 credits - $60"
            {...getOverrideProps(overrides, "Button38083276")}
          ></Button>
          <Button
            width="unset"
            height="72px"
            grow="1"
            shrink="1"
            basis="0"
            size="large"
            isDisabled={false}
            variation="primary"
            children="Buy 80 credits - $68"
            {...getOverrideProps(overrides, "Button38083280")}
          ></Button>
        </Flex>
      </Flex>
      <Flex
        gap="19px"
        direction="row"
        width="unset"
        height="120px"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="24px 18px 24px 18px"
        backgroundColor="rgba(255,255,255,1)"
        display="flex"
        {...getOverrideProps(overrides, "Frame 431")}
      >
        <Button
          width="unset"
          height="unset"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          size="large"
          isDisabled={false}
          variation="primary"
          children="Buy 90 credits - $75"
          {...getOverrideProps(overrides, "Button38083285")}
        ></Button>
        <Button
          width="unset"
          height="unset"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          size="large"
          isDisabled={false}
          variation="primary"
          children="Buy 100 credits - $80"
          {...getOverrideProps(overrides, "Button38083251")}
        ></Button>
        <Button
          width="unset"
          height="unset"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          size="large"
          isDisabled={false}
          variation="primary"
          children="Buy 200 credits - $150"
          {...getOverrideProps(overrides, "Button38083255")}
        ></Button>
      </Flex>
      <Flex
        gap="19px"
        direction="row"
        width="unset"
        height="105px"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="24px 18px 24px 18px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 450")}
      >
        <Button
          width="unset"
          height="72px"
          grow="1"
          shrink="1"
          basis="0"
          size="large"
          isDisabled={false}
          variation="primary"
          children="Buy 500 credits - $350"
          {...getOverrideProps(overrides, "Button38083260")}
        ></Button>
        <Button
          width="unset"
          height="72px"
          grow="1"
          shrink="1"
          basis="0"
          size="large"
          isDisabled={false}
          variation="primary"
          children="Buy 1000 credits - $650"
          {...getOverrideProps(overrides, "Button38083289")}
        ></Button>
      </Flex>
    </Flex>
  );
}
