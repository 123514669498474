import { API } from 'aws-amplify';

const getApi = async (
  path,
  queryStringParameters,
  onError = () => {},
  onSuccess = () => {},
) => {
  let data, error;
  const apiName = 'api';
  try {
    const response = await API.get(apiName, path, {
      queryStringParameters,
    });
    error = response.error;
    data = response.data;
  } catch (err) {
    console.log({ err });
    error = err?.response?.data?.error || `${err}`;
  }

  if (error) {
    onError(error);
  } else {
    onSuccess(data);
  }
};

const postApi = async (path, body, onError = () => {}, onSuccess =() => {}) => {
  let data, error;
  const apiName = 'api';
  try {
    const response = await API.post(apiName, path, {
      body,
    });
    error = response.error;
    data = response.data;
  } catch (err) {
    console.log({ err });
    error = err?.response?.data?.error || `${err}`;
    console.log({ error });
  }

  if (error) {
    onError(error);
  } else {
    onSuccess(data);
  }
};

export { postApi, getApi };
