import { View, Flex, useBreakpointValue } from '@aws-amplify/ui-react';
import {} from '../ui-components';
export function Investors() {
  const breakpoint = useBreakpointValue({
    base: 'base',
    small: 'small',
    medium: 'medium',
    large: 'large',
    xl: 'xl',
    xxl: 'xxl',
  });
  console.log({ breakpoint });

  return (
    <View className="App-header">
      <Flex
        direction="column"
        style={{
          width: breakpoint === 'xxl' ? 1480 : '90%',

          paddingTop: 12,
          paddingBottom: 12,
        }}
      >
        <Flex
          direction="column"
          style={{ padding: 24, backgroundColor: 'rgba(255,255,255,1)' }}
        >
          <h1>To Investors</h1>
          <p>
            Welcome to our writing booster, this page is dedicated to providing
            information for potential investors who are interested in learning
            more about our company and the investment opportunities we offer.
          </p>
          <p>
            At our company, we are committed to innovation and growth, and we
            are always seeking new investors who share our vision and values.
            With your support, we believe we can continue to expand our
            operations, develop new products and services, and make a positive
            impact in our industry and the wider community.
          </p>
          <h3>
            Here are just a few reasons why you should consider investing in our
            company:
          </h3>
          <ul class="custom">
            <li>
              Strong leadership: Our company is led by a team of experienced and
              knowledgeable professionals who have a proven track record of
              success in our industry. We have a clear vision for the future of
              our company and are committed to achieving our goals through
              smart, strategic decision-making.
            </li>
            <li>
              Innovative products and services: We are constantly pushing the
              boundaries of what is possible in our industry, developing new
              products and services that meet the changing needs of our
              customers. Our commitment to innovation ensures that we stay ahead
              of the curve and continue to grow and thrive in a rapidly changing
              market.
            </li>
            <li>
              Financial stability: We have a strong financial foundation and a
              solid track record of growth and profitability. Our financial
              statements are transparent and easy to understand, giving you the
              confidence you need to make an informed investment decision.
            </li>
            <li>
              Social responsibility: We are committed to making a positive
              impact in our industry and the wider community. We believe that
              success should be measured not just in financial terms, but also
              in terms of the positive contributions we make to society and the
              environment.
            </li>
          </ul>

          <p>
            If you are interested in learning more about our company and the
            investment opportunities we offer, please don't hesitate to contact
            us. We would be happy to provide you with more information about our
            financial performance, growth strategy, and investment options, and
            answer any questions you may have. Thank you for considering
            investing in our company, and we look forward to hearing from you
            soon.
          </p>
        </Flex>
      </Flex>
    </View>
  );
}
