/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, TextAreaField } from "@aws-amplify/ui-react";
export default function RewriteCard(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        TextAreaField37513024: {},
        TextAreaField37513079: {},
        TextAreaField37513086: {},
        "Frame 444": {},
        RewriteCard: {},
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        TextAreaField37513024: { alignSelf: "stretch" },
        TextAreaField37513079: { alignSelf: "stretch" },
        TextAreaField37513086: { shrink: "0", alignSelf: "stretch" },
        "Frame 444": { direction: "column", alignSelf: "stretch" },
        RewriteCard: { width: "480px" },
      },
      variantValues: { breakpoint: "small" },
    },
    {
      overrides: {
        TextAreaField37513024: { alignSelf: "stretch" },
        TextAreaField37513079: { alignSelf: "stretch" },
        TextAreaField37513086: { shrink: "0", alignSelf: "stretch" },
        "Frame 444": {
          direction: "column",
          width: "480px",
          shrink: "0",
          alignSelf: "stretch",
        },
        RewriteCard: { width: "unset" },
      },
      variantValues: { breakpoint: "base" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="0"
      direction="row"
      width="1440px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "RewriteCard")}
    >
      <Flex
        gap="24px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        overflow="hidden"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="12px 24px 12px 24px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 444")}
      >
        <TextAreaField
          width="unset"
          height="unset"
          label="Original"
          shrink="0"
          placeholder="Placeholder"
          size="default"
          isDisabled={true}
          labelHidden={false}
          variation="default"
          {...getOverrideProps(overrides, "TextAreaField37513024")}
        ></TextAreaField>
        <TextAreaField
          width="unset"
          height="unset"
          label="Rewrite"
          shrink="0"
          placeholder="Placeholder"
          size="default"
          isDisabled={true}
          labelHidden={false}
          variation="default"
          {...getOverrideProps(overrides, "TextAreaField37513079")}
        ></TextAreaField>
        <TextAreaField
          width="unset"
          height="unset"
          label="Reason"
          grow="1"
          shrink="1"
          basis="0"
          placeholder="Placeholder"
          size="default"
          isDisabled={true}
          labelHidden={false}
          variation="default"
          {...getOverrideProps(overrides, "TextAreaField37513086")}
        ></TextAreaField>
      </Flex>
    </Flex>
  );
}
