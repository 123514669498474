import { useEffect, useState } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { View, Flex, useBreakpointValue } from '@aws-amplify/ui-react';


import { useProfileContext } from '../hookers/profileContext';

export function PaymentSuccess() {
    const { route } = useAuthenticator((context) => [context.route]);
    const { refreshProfile } = useProfileContext();

    useEffect(() => {
        refreshProfile(route);
        return () => {};
    }, []);

    const breakpoint = useBreakpointValue({
    base: 'base',
    small: 'small',
    medium: 'medium',
    large: 'large',
    xl: 'xl',
    xxl: 'xxl',
    });
    return (
    <View className="App-header">
        <Flex
        direction="column"
        style={{
            width: breakpoint === 'xxl' ? 1480 : '90%',
        }}
        >
        <Flex
            direction="column"
            style={{ padding: 24, backgroundColor: 'rgba(255,255,255,1)' }}
        >
            <h1>Thanks for your Purchase!</h1>
            <p>
                We appreciate your purchase!
                If you have any questions, please email: 
            <a href="mailto:writingbooster.customerservice1@gmail.com">writingbooster.customerservice1@gmail.com</a>.
            </p>
            <a
                href="/"
            >
                Click here to go to index page
            </a>
        </Flex>
        
        </Flex>
    </View>
    );
}
