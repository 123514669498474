import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import { Outlet, useNavigate } from 'react-router-dom';
import { View } from '@aws-amplify/ui-react';
import { Text, useTheme, useBreakpointValue } from '@aws-amplify/ui-react';
import { MarketingFooter, MarketingFooterResponsive } from '../ui-components';

// import NavBar from './NavBar';
import NavBarResponsive from './NavBarResponsive';
// import { NavBarResponsive } from '../ui-components';
import { useProfileContext } from '../hookers/profileContext';

export function Layout() {
  const { tokens } = useTheme();
  const navigate = useNavigate();

  const goWritingsamples = () => navigate('/writingsamples');
  const goTips = () => navigate('/tips');
  const goAboutMe = () => navigate('/aboutme');
  const goIndex = () => navigate('/');
  const goCareers = () => navigate('/careers');
  const goInvestors = () => navigate('/investors');
  const goPrivacy = () => navigate('/privacy');
  const goTermCondition = () => navigate('/termcondition');

  const breakpoint = useBreakpointValue({
    base: 'base',
    small: 'small',
    medium: 'medium',
    large: 'large',
    xl: 'xl',
    xxl: 'xxl',
  });

  // console.log({ breakpoint });
  const { profile } = useProfileContext();

  const [fromEmail, setFromEmail] = useState('');
  const [message, setMessage] = useState('');

  const [emailHasError, setEmailHasError] = useState(false);
  const [messageHasError, setMessageHasError] = useState(false);

  const [isSendingLoading, setIsSendingLoading] = useState(false);

  const validateEmail = email => {
    const valid = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
    setEmailHasError(!valid);
    return valid;
  };

  const validateMessage = message => {
    const valid = message && message.length;
    setMessageHasError(!valid);
    return valid;
  };

  // console.log({ profile });
  return (
    <View
      // height={'100%'}
      // width={'100%'}
      backgroundColor={tokens.colors.background.primary}
      fontFamily={''}
      // fontWeight={tokens.fontWeights.bold}
    >
      <View>
        <NavBarResponsive
          breakpoint={breakpoint}
          overrides={{
            NavBarResponsive: { width: 'unset' },
            label: { fontFamily: '' },
            fullLogo: {
              src: 'full-logo.png',
            },
            'icon 2': {
              src: 'icon.png',
            },
            Button37683080: {
              fontFamily: '',
              fontWeight: 400,
              color: 'rgb(229, 229, 229)',
              onClick: goIndex,
            },
            Button37693101: {
              fontFamily: '',
              fontWeight: 400,
              color: 'rgb(229, 229, 229)',
              onClick: goWritingsamples,
            },
            Button37683093: {
              fontFamily: '',
              fontWeight: 400,
              color: 'rgb(229, 229, 229)',
              onClick: goTips,
            },
            Badge: {
              children: `${profile?.credits || 0} credits`,
            },
          }}
        />
        {/* {route !== 'authenticated' ? (
          <Button onClick={() => navigate('/login')}>Login</Button>
        ) : (
          <Button onClick={() => logOut()}>Logout</Button>
        )} */}
      </View>

      <Outlet />

      <View>
        <MarketingFooterResponsive
          breakpoint={breakpoint}
          overrides={{
            MarketingFooterResponsive: { width: 'unset' },
            text1: { fontFamily: '', onClick: () => {} },
            text2: { fontFamily: '' },
            text3: { fontFamily: '' },
            text4: { fontFamily: '' },
            YourEmail: {
              backgroundColor: 'rgb(229, 229, 229)',
              type: 'email',
              isRequired: true,
              hasError: emailHasError,
              errorMessage: 'Invalid email',
              value: fromEmail,
              onChange: e => {
                setFromEmail(e.currentTarget.value);
                setEmailHasError(false);
              },
            },
            TextAreaField: {
              backgroundColor: 'rgb(229, 229, 229)',
              isRequired: true,
              value: message,
              hasError: messageHasError,
              errorMessage: 'This field is required',
              onChange: e => {
                setMessage(e.currentTarget.value);
                setMessageHasError(false);
              },
            },
            Button: {
              isLoading: isSendingLoading,
              onClick: () => {
                if (!validateEmail(fromEmail) || !validateMessage(message)) {
                  return;
                }
                setIsSendingLoading(true);
                emailjs.send(
                  'service_rumfnye',
                  'template_6d6q6br',
                  { from_email: fromEmail, message },
                  '-vsDhVHve-3DtJvz0',
                );
                setIsSendingLoading(false);
                setMessage('');
                setFromEmail('');

                // emailjs.send('', 'template_6d6q6br');
                // emailjs
                //   .sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', '#myForm')
                //   .then(
                //     function (response) {
                //       console.log('SUCCESS!', response.status, response.text);
                //     },
                //     function (error) {
                //       console.log('FAILED...', error);
                //     },
                //   );
              },
            },
            Review: {
              fontWeight: 400,
              color: 'rgb(229, 229, 229)',
              onClick: goIndex,
            },
            'Writing Sample': {
              fontWeight: 400,
              color: 'rgb(229, 229, 229)',
              onClick: goWritingsamples,
            },
            'Writing tips': {
              fontWeight: 400,
              color: 'rgb(229, 229, 229)',
              onClick: goTips,
            },
            AboutUs: {
              fontWeight: 400,
              color: 'rgb(229, 229, 229)',
              onClick: goAboutMe,
            },
            Careers: {
              fontWeight: 400,
              color: 'rgb(229, 229, 229)',
              onClick: goCareers,
            },
            Investors: {
              fontWeight: 400,
              color: 'rgb(229, 229, 229)',
              onClick: goInvestors,
            },
            TermCondition: {
              fontWeight: 400,
              color: 'rgb(229, 229, 229)',
              onClick: goTermCondition,
            },
            Privacy: {
              fontWeight: 400,
              color: 'rgb(229, 229, 229)',
              onClick: goPrivacy,
            },
          }}
        />
      </View>
    </View>
  );
}
