import { Authenticator } from '@aws-amplify/ui-react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { WritingSample } from './pages/WritingSample';
import { Login } from './pages/Login';
import { Tips } from './pages/Tips';
import { Review } from './pages/Review';
import { BuyCredits } from './pages/BuyCredits';
import { EditProfile } from './pages/EditProfile';
import { ViewHistory } from './pages/ViewHistory';
import { TermCondition } from './pages/TermCondition';
import { Privacy } from './pages/Privacy';
import { AboutMe } from './pages/AboutMe';
import { Investors } from './pages/Investors';
import { Careers } from './pages/Careers';

import { Layout } from './components/Layout';
import { RequireAuth } from './RequireAuth';
import { ProfileProvider } from './hookers/profileContext';

import './App.css';
import { PaymentSuccess } from './pages/PaymentSuccess';
import { PaymentCancel } from './pages/PaymentCancel';
const reload = () => window.location.reload();

function MyRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Review />} />
          <Route
            path="/writingsamples"
            element={
              <RequireAuth>
                <WritingSample />
              </RequireAuth>
            }
          />
          <Route
            path="/tips"
            element={
              <RequireAuth>
                <Tips />
              </RequireAuth>
            }
          />
          <Route
            path="/editprofile"
            element={
              <RequireAuth>
                <EditProfile />
              </RequireAuth>
            }
          />
          <Route
            path="/viewhistory"
            element={
              <RequireAuth>
                <ViewHistory />
              </RequireAuth>
            }
          />
          <Route
            path="/buycredits"
            element={
              <RequireAuth>
                <BuyCredits />
              </RequireAuth>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/termcondition" element={<TermCondition />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/aboutme" element={<AboutMe />} />
          <Route path="/investors" element={<Investors />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/paymentsuccess" element={<PaymentSuccess />} />
          <Route path="/paymentcancel" element={<PaymentCancel />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function App() {
  return (
    <Authenticator.Provider>
      <ProfileProvider>
        <MyRoutes />
      </ProfileProvider>
    </Authenticator.Provider>
  );
}

export default App;
