import React from 'react';
import Modal from 'react-modal';

import { Flex, View, Alert } from '@aws-amplify/ui-react';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    padding: '0',
    transform: 'translate(-50%, -50%)',
  },
};

const MessageBox = ({ modalIsOpen, closeModal, msgHeading, msgContent }) => {
  return (
    <Modal isOpen={modalIsOpen} style={customStyles}>
      <Alert
        variation="info"
        isDismissible={true}
        onDismiss={closeModal}
        hasIcon={true}
        heading={msgHeading}
      >
        {msgContent}
      </Alert>
    </Modal>
  );
};

export { MessageBox };
