/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import {
  Badge,
  Divider,
  Flex,
  Icon,
  Rating,
  Text,
  View,
} from "@aws-amplify/ui-react";
export default function HistorySampleCard(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        Badge: {},
        createdTime: {},
        "Frame 440": {},
        "Information about this product": {},
        Vector: {},
        Icon: {},
        Frame: {},
        Instruction: {},
        Title: {},
        Divider38303323: {},
        "Information about this product.38303325": {},
        "Information about this product.38303326": {},
        vocabulary: {},
        wordCount: {},
        writeType: {},
        Features: {},
        Rating38303330: {},
        Reviews: {},
        Rating38303329: {},
        "Bottom Row": {},
        "Card Area38303316": {},
        Divider38303431: {},
        OriginalContent: {},
        Divider38303888: {},
        RewriteContent: {},
        "Card Area38303345": {},
        HistorySampleCard: {},
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        Badge: {},
        createdTime: {},
        "Frame 440": {},
        "Information about this product": {},
        Vector: {},
        Icon: {},
        Frame: {},
        Instruction: {},
        Title: {},
        Divider38303323: {},
        "Information about this product.38303325": {},
        "Information about this product.38303326": {},
        vocabulary: {},
        wordCount: {},
        writeType: {},
        Features: {},
        Rating38303330: {},
        Reviews: {},
        Rating38303329: {},
        "Bottom Row": {},
        "Card Area38303316": {},
        Divider38303431: {
          height: "1px",
          orientation: "horizontal",
          width: "unset",
        },
        OriginalContent: { width: "201px", shrink: "0" },
        Divider38303888: {},
        RewriteContent: {},
        "Card Area38303345": { shrink: "0" },
        HistorySampleCard: {
          direction: "column",
          width: "unset",
          justifyContent: "center",
          alignItems: "flex-start",
        },
      },
      variantValues: { breakpoint: "small" },
    },
    {
      overrides: {
        Badge: {},
        createdTime: {},
        "Frame 440": {},
        "Information about this product": {},
        Vector: {},
        Icon: {},
        Frame: {},
        Instruction: {},
        Title: {},
        Divider38303323: {},
        "Information about this product.38303325": {},
        "Information about this product.38303326": {},
        vocabulary: {},
        wordCount: {},
        writeType: {},
        Features: {},
        Rating38303330: {},
        Reviews: {},
        Rating38303329: {},
        "Bottom Row": {},
        "Card Area38303316": {},
        Divider38303431: {
          height: "1px",
          orientation: "horizontal",
          width: "unset",
        },
        OriginalContent: { shrink: "0" },
        Divider38303888: {},
        RewriteContent: {},
        "Card Area38303345": { shrink: "0" },
        HistorySampleCard: {
          direction: "column",
          width: "unset",
          justifyContent: "center",
          alignItems: "flex-start",
        },
      },
      variantValues: { breakpoint: "base" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="0"
      direction="row"
      width="1440px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="3px 0px 3px 0px"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "HistorySampleCard")}
    >
      <Flex
        gap="16px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="24px 24px 24px 24px"
        backgroundColor="rgba(255,255,255,1)"
        display="flex"
        {...getOverrideProps(overrides, "Card Area38303316")}
      >
        <Flex
          gap="10px"
          direction="row"
          width="unset"
          height="30px"
          justifyContent="flex-start"
          alignItems="flex-start"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 440")}
        >
          <Badge
            width="unset"
            height="unset"
            shrink="0"
            size="default"
            variation="success"
            children="Writing Sample"
            {...getOverrideProps(overrides, "Badge")}
          ></Badge>
          <Text
            fontFamily="Inter"
            fontSize="12px"
            fontWeight="600"
            color="rgba(13,26,38,1)"
            lineHeight="20px"
            textAlign="right"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Apr 12 2023  19:00"
            {...getOverrideProps(overrides, "createdTime")}
          ></Text>
        </Flex>
        <Flex
          gap="8px"
          direction="column"
          width="336px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Title")}
        >
          <Flex
            gap="8px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-end"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="none"
            {...getOverrideProps(overrides, "Frame")}
          >
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(92,102,112,1)"
              lineHeight="24px"
              textAlign="left"
              display="none"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Information about this product"
              {...getOverrideProps(overrides, "Information about this product")}
            ></Text>
            <View
              width="24px"
              height="24px"
              display="none"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              overflow="hidden"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Icon")}
            >
              <Icon
                width="20px"
                height="18.35px"
                viewBox={{ minX: 0, minY: 0, width: 20, height: 18.349609375 }}
                paths={[
                  {
                    d: "M14.5 0C12.76 0 11.09 0.81 10 2.09C8.91 0.81 7.24 0 5.5 0C2.42 0 0 2.42 0 5.5C0 9.28 3.4 12.36 8.55 17.04L10 18.35L11.45 17.03C16.6 12.36 20 9.28 20 5.5C20 2.42 17.58 0 14.5 0ZM10.1 15.55L10 15.65L9.9 15.55C5.14 11.24 2 8.39 2 5.5C2 3.5 3.5 2 5.5 2C7.04 2 8.54 2.99 9.07 4.36L10.94 4.36C11.46 2.99 12.96 2 14.5 2C16.5 2 18 3.5 18 5.5C18 8.39 14.86 11.24 10.1 15.55Z",
                    fill: "rgba(13,26,38,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="12.5%"
                bottom="11.04%"
                left="8.33%"
                right="8.33%"
                {...getOverrideProps(overrides, "Vector")}
              ></Icon>
            </View>
          </Flex>
          <Text
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="700"
            color="rgba(13,26,38,1)"
            lineHeight="25px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Write a story about a person who discovers a mysterious object that changes their life in unexpected ways."
            {...getOverrideProps(overrides, "Instruction")}
          ></Text>
        </Flex>
        <Divider
          width="unset"
          height="1px"
          shrink="0"
          alignSelf="stretch"
          size="small"
          orientation="horizontal"
          {...getOverrideProps(overrides, "Divider38303323")}
        ></Divider>
        <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-end"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Features")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color="rgba(92,102,112,1)"
            lineHeight="24px"
            textAlign="left"
            display="none"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.01px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Information about this product."
            {...getOverrideProps(
              overrides,
              "Information about this product.38303325"
            )}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color="rgba(92,102,112,1)"
            lineHeight="24px"
            textAlign="left"
            display="none"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.01px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Information about this product."
            {...getOverrideProps(
              overrides,
              "Information about this product.38303326"
            )}
          ></Text>
          <Badge
            width="unset"
            height="unset"
            shrink="0"
            size="default"
            variation="warning"
            children="Vocabulary"
            {...getOverrideProps(overrides, "vocabulary")}
          ></Badge>
          <Badge
            width="unset"
            height="unset"
            shrink="0"
            size="default"
            variation="warning"
            children="Word Count"
            {...getOverrideProps(overrides, "wordCount")}
          ></Badge>
          <Badge
            width="unset"
            height="unset"
            shrink="0"
            size="default"
            variation="warning"
            children="Write Type"
            {...getOverrideProps(overrides, "writeType")}
          ></Badge>
        </Flex>
        <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="12px 0px 12px 0px"
          display="none"
          {...getOverrideProps(overrides, "Bottom Row")}
        >
          <Flex
            gap="16px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="none"
            {...getOverrideProps(overrides, "Rating38303329")}
          >
            <Rating
              width="unset"
              height="unset"
              shrink="0"
              size="default"
              {...getOverrideProps(overrides, "Rating38303330")}
            ></Rating>
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(13,26,38,1)"
              lineHeight="22px"
              textAlign="left"
              display="none"
              direction="column"
              justifyContent="unset"
              textDecoration="underline"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Reviews"
              {...getOverrideProps(overrides, "Reviews")}
            ></Text>
          </Flex>
        </Flex>
      </Flex>
      <Divider
        height="unset"
        shrink="0"
        alignSelf="stretch"
        size="default"
        orientation="vertical"
        {...getOverrideProps(overrides, "Divider38303431")}
      ></Divider>
      <Flex
        gap="24px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        grow="1"
        shrink="1"
        basis="0"
        alignSelf="stretch"
        position="relative"
        padding="24px 24px 24px 24px"
        backgroundColor="rgba(255,255,255,1)"
        display="flex"
        {...getOverrideProps(overrides, "Card Area38303345")}
      >
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="400"
          color="rgba(92,102,112,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Information about this product"
          {...getOverrideProps(overrides, "OriginalContent")}
        ></Text>
        <Divider
          height="unset"
          display="none"
          shrink="0"
          alignSelf="stretch"
          size="small"
          orientation="vertical"
          {...getOverrideProps(overrides, "Divider38303888")}
        ></Divider>
        <Text
          fontFamily="Inter"
          fontSize="14px"
          fontWeight="400"
          color="rgba(92,102,112,1)"
          lineHeight="24px"
          textAlign="left"
          display="none"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Information about this product"
          {...getOverrideProps(overrides, "RewriteContent")}
        ></Text>
      </Flex>
    </Flex>
  );
}
