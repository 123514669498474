import { useState, useEffect } from 'react';
import { View, Flex } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import { EditProfileUI } from '../ui-components';
import {
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
  avatar6,
  avatar7,
  avatar8,
} from '../images';

import { useProfileContext, defaultProfile } from '../hookers/profileContext';

export function EditProfile() {
  const [profileState, setProfileState] = useState(defaultProfile);
  const { profile, isLoading, updateProfile } = useProfileContext();
  const navigate = useNavigate();

  console.log({ profile });
  useEffect(() => {
    if (!isLoading) {
      const { avatar, credits, name, email } = profile;
      console.log({ avatar, credits, name: name || '', email });
      setProfileState({ avatar, credits, name: name || '', email });
    }
  }, [profile, isLoading]);

  const setAvatar = (value) => {
    setProfileState({
      ...profileState,
      avatar: value,
    });
  };

  const setName = (value) => {
    setProfileState({
      ...profileState,
      name: value,
    });
  };

  const onCancel = () => navigate('/');

  // console.log({ profile: profile.avatar === 1 });
  return (
    <View className="App-header">
      <Flex direction="column">
        <EditProfileUI
          overrides={{
            'Select Avatar': {
              fontFamily: '',
            },
            image38243439: {
              src: avatar1,
            },
            Radio38243518: {
              checked: profileState.avatar === 1,
              onClick: () => {
                setAvatar(1);
              },
            },
            image38243598: {
              src: avatar2,
            },
            Radio38243599: {
              checked: profileState.avatar === 2,
              onClick: () => {
                setAvatar(2);
              },
            },
            image38243606: {
              src: avatar3,
            },
            Radio38243607: {
              checked: profileState.avatar === 3,
              onClick: () => {
                setAvatar(3);
              },
            },
            image38243614: {
              src: avatar4,
            },
            Radio38243615: {
              checked: profileState.avatar === 4,
              onClick: () => {
                setAvatar(4);
              },
            },
            image38243623: {
              src: avatar5,
            },
            Radio38243624: {
              checked: profileState.avatar === 5,
              onClick: () => {
                setAvatar(5);
              },
            },
            image38243626: {
              src: avatar6,
            },
            Radio38243627: {
              checked: profileState.avatar === 6,
              onClick: () => {
                setAvatar(6);
              },
            },
            image38243629: {
              src: avatar7,
            },
            Radio38243630: {
              checked: profileState.avatar === 7,
              onClick: () => {
                setAvatar(7);
              },
            },
            image38243632: {
              src: avatar8,
            },
            Radio38243633: {
              checked: profileState.avatar === 8,
              onClick: () => {
                setAvatar(8);
              },
            },
            TextField38243442: {
              value: profileState.name,
              placeholder: '',
              onChange: (e) => {
                setName(e.currentTarget.value);
              },
            },
            TextField38243444: {
              placeholder: '',
              value: profileState.email,
            },
            Button38243679: {
              type: 'submit',
              isLoading,
              onClick: () => {
                updateProfile(profileState)
              },
            },
            Button38243683: {
              onClick: onCancel,
            },
          }}
        />
      </Flex>
    </View>
  );
}
