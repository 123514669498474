/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
  useStateMutationAction,
} from "@aws-amplify/ui-react/internal";
import {
  Badge,
  Divider,
  Flex,
  Icon,
  Rating,
  Text,
  View,
} from "@aws-amplify/ui-react";
export default function HistoryReviewCard(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        Badge: {},
        createdTime: {},
        "Frame 440": {},
        "Information about this product": {},
        Vector: {},
        Icon: {},
        Frame: {},
        Instruction: {},
        Title: {},
        Divider38303494: {},
        "Information about this product.38303496": {},
        "Information about this product.38303497": {},
        vocabulary: {},
        wordCount: {},
        writeType: {},
        Features: {},
        Reviews38303504: {},
        Rating38303503: {},
        Rating38303502: {},
        Reviews38303607: {},
        Rating38303606: {},
        Rating38303605: {},
        Reviews38303620: {},
        Rating38303619: {},
        Rating38303618: {},
        Reviews38303633: {},
        Rating38303632: {},
        Rating38303631: {},
        Reviews38303646: {},
        Rating38303645: {},
        Rating38303644: {},
        Reviews38303659: {},
        Rating38303658: {},
        Rating38303657: {},
        "Bottom Row": {},
        "Card Area38303484": {},
        Divider38303505: {},
        "Original Writing": {},
        OriginalContent: {},
        "Frame 441": {},
        Divider38303884: {},
        Rewrite: {},
        RewriteContent: {},
        "Frame 442": {},
        "Card Area38303506": {},
        HistoryReviewCard: {},
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        Badge: {},
        createdTime: {},
        "Frame 440": {},
        "Information about this product": {},
        Vector: {},
        Icon: {},
        Frame: {},
        Instruction: {},
        Title: {},
        Divider38303494: {},
        "Information about this product.38303496": {},
        "Information about this product.38303497": {},
        vocabulary: {},
        wordCount: {},
        writeType: {},
        Features: {},
        Reviews38303504: {},
        Rating38303503: {},
        Rating38303502: {},
        Reviews38303607: {},
        Rating38303606: {},
        Rating38303605: {},
        Reviews38303620: {},
        Rating38303619: {},
        Rating38303618: {},
        Reviews38303633: {},
        Rating38303632: {},
        Rating38303631: {},
        Reviews38303646: {},
        Rating38303645: {},
        Rating38303644: {},
        Reviews38303659: {},
        Rating38303658: {},
        Rating38303657: {},
        "Bottom Row": {},
        "Card Area38303484": {},
        Divider38303505: {
          height: "1px",
          orientation: "horizontal",
          width: "unset",
        },
        "Original Writing": {},
        OriginalContent: {},
        "Frame 441": { shrink: "0", alignSelf: "stretch" },
        Divider38303884: {
          height: "1px",
          size: "default",
          orientation: "horizontal",
          width: "unset",
        },
        Rewrite: {},
        RewriteContent: {},
        "Frame 442": { shrink: "0", alignSelf: "stretch" },
        "Card Area38303506": { direction: "column", shrink: "0" },
        HistoryReviewCard: {
          direction: "column",
          width: "unset",
          justifyContent: "center",
          alignItems: "flex-start",
        },
      },
      variantValues: { breakpoint: "base" },
    },
    {
      overrides: {
        Badge: {},
        createdTime: {},
        "Frame 440": {},
        "Information about this product": {},
        Vector: {},
        Icon: {},
        Frame: {},
        Instruction: {},
        Title: {},
        Divider38303494: {},
        "Information about this product.38303496": {},
        "Information about this product.38303497": {},
        vocabulary: {},
        wordCount: {},
        writeType: {},
        Features: {},
        Reviews38303504: {},
        Rating38303503: {},
        Rating38303502: {},
        Reviews38303607: {},
        Rating38303606: {},
        Rating38303605: {},
        Reviews38303620: {},
        Rating38303619: {},
        Rating38303618: {},
        Reviews38303633: {},
        Rating38303632: {},
        Rating38303631: {},
        Reviews38303646: {},
        Rating38303645: {},
        Rating38303644: {},
        Reviews38303659: {},
        Rating38303658: {},
        Rating38303657: {},
        "Bottom Row": {},
        "Card Area38303484": {},
        Divider38303505: {
          height: "1px",
          orientation: "horizontal",
          width: "unset",
        },
        "Original Writing": {},
        OriginalContent: {},
        "Frame 441": { shrink: "0", alignSelf: "stretch" },
        Divider38303884: {
          height: "1px",
          size: "default",
          orientation: "horizontal",
          width: "unset",
        },
        Rewrite: {},
        RewriteContent: {},
        "Frame 442": { shrink: "0", alignSelf: "stretch" },
        "Card Area38303506": { direction: "column", shrink: "0" },
        HistoryReviewCard: {
          direction: "column",
          width: "unset",
          justifyContent: "center",
          alignItems: "flex-start",
        },
      },
      variantValues: { breakpoint: "small" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  const [badgeMarginBottom, setBadgeMarginBottom] =
    useStateMutationAction(undefined);
  const ratingThreeEightThreeZeroThreeFiveZeroThreeOnMouseEnter = () => {
    setBadgeMarginBottom("1");
  };
  return (
    <Flex
      gap="0"
      direction="row"
      width="1440px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="3px 0px 3px 0px"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "HistoryReviewCard")}
    >
      <Flex
        gap="16px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="24px 24px 24px 24px"
        backgroundColor="rgba(255,255,255,1)"
        display="flex"
        {...getOverrideProps(overrides, "Card Area38303484")}
      >
        <Flex
          gap="10px"
          direction="row"
          width="unset"
          height="30px"
          justifyContent="flex-start"
          alignItems="flex-start"
          overflow="hidden"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 440")}
        >
          <Badge
            width="unset"
            height="unset"
            shrink="0"
            size="default"
            variation="info"
            children="Review"
            marginBottom={badgeMarginBottom}
            {...getOverrideProps(overrides, "Badge")}
          ></Badge>
          <Text
            fontFamily="Inter"
            fontSize="12px"
            fontWeight="600"
            color="rgba(13,26,38,1)"
            lineHeight="20px"
            textAlign="right"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Apr 12 2023  19:00"
            {...getOverrideProps(overrides, "createdTime")}
          ></Text>
        </Flex>
        <Flex
          gap="8px"
          direction="column"
          width="336px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Title")}
        >
          <Flex
            gap="8px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-end"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="none"
            {...getOverrideProps(overrides, "Frame")}
          >
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              color="rgba(92,102,112,1)"
              lineHeight="24px"
              textAlign="left"
              display="none"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Information about this product"
              {...getOverrideProps(overrides, "Information about this product")}
            ></Text>
            <View
              width="24px"
              height="24px"
              display="none"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              overflow="hidden"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Icon")}
            >
              <Icon
                width="20px"
                height="18.35px"
                viewBox={{ minX: 0, minY: 0, width: 20, height: 18.349609375 }}
                paths={[
                  {
                    d: "M14.5 0C12.76 0 11.09 0.81 10 2.09C8.91 0.81 7.24 0 5.5 0C2.42 0 0 2.42 0 5.5C0 9.28 3.4 12.36 8.55 17.04L10 18.35L11.45 17.03C16.6 12.36 20 9.28 20 5.5C20 2.42 17.58 0 14.5 0ZM10.1 15.55L10 15.65L9.9 15.55C5.14 11.24 2 8.39 2 5.5C2 3.5 3.5 2 5.5 2C7.04 2 8.54 2.99 9.07 4.36L10.94 4.36C11.46 2.99 12.96 2 14.5 2C16.5 2 18 3.5 18 5.5C18 8.39 14.86 11.24 10.1 15.55Z",
                    fill: "rgba(13,26,38,1)",
                    fillRule: "nonzero",
                  },
                ]}
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                position="absolute"
                top="12.5%"
                bottom="11.04%"
                left="8.33%"
                right="8.33%"
                {...getOverrideProps(overrides, "Vector")}
              ></Icon>
            </View>
          </Flex>
          <Text
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="700"
            color="rgba(13,26,38,1)"
            lineHeight="25px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Write a story about a person who discovers a mysterious object that changes their life in unexpected ways."
            {...getOverrideProps(overrides, "Instruction")}
          ></Text>
        </Flex>
        <Divider
          width="unset"
          height="1px"
          shrink="0"
          alignSelf="stretch"
          size="small"
          orientation="horizontal"
          {...getOverrideProps(overrides, "Divider38303494")}
        ></Divider>
        <Flex
          gap="16px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Features")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color="rgba(92,102,112,1)"
            lineHeight="24px"
            textAlign="left"
            display="none"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.01px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Information about this product."
            {...getOverrideProps(
              overrides,
              "Information about this product.38303496"
            )}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color="rgba(92,102,112,1)"
            lineHeight="24px"
            textAlign="left"
            display="none"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.01px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Information about this product."
            {...getOverrideProps(
              overrides,
              "Information about this product.38303497"
            )}
          ></Text>
          <Badge
            width="unset"
            height="unset"
            shrink="0"
            size="default"
            variation="warning"
            children="Vocabulary"
            {...getOverrideProps(overrides, "vocabulary")}
          ></Badge>
          <Badge
            width="unset"
            height="unset"
            shrink="0"
            size="default"
            variation="warning"
            children="Word Count"
            {...getOverrideProps(overrides, "wordCount")}
          ></Badge>
          <Badge
            width="unset"
            height="unset"
            shrink="0"
            size="default"
            variation="warning"
            children="Write Type"
            {...getOverrideProps(overrides, "writeType")}
          ></Badge>
        </Flex>
        <Flex
          gap="12px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="12px 12px 12px 12px"
          display="flex"
          {...getOverrideProps(overrides, "Bottom Row")}
        >
          <Flex
            gap="16px"
            direction="row"
            width="280px"
            height="22px"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Rating38303502")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(13,26,38,1)"
              lineHeight="22px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              textDecoration="underline"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Content"
              {...getOverrideProps(overrides, "Reviews38303504")}
            ></Text>
            <Rating
              width="unset"
              height="unset"
              shrink="0"
              size="small"
              onMouseEnter={() => {
                ratingThreeEightThreeZeroThreeFiveZeroThreeOnMouseEnter();
              }}
              {...getOverrideProps(overrides, "Rating38303503")}
            ></Rating>
          </Flex>
          <Flex
            gap="16px"
            direction="row"
            width="280px"
            height="22px"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Rating38303605")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(13,26,38,1)"
              lineHeight="22px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              textDecoration="underline"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Crammar"
              {...getOverrideProps(overrides, "Reviews38303607")}
            ></Text>
            <Rating
              width="unset"
              height="unset"
              shrink="0"
              size="small"
              {...getOverrideProps(overrides, "Rating38303606")}
            ></Rating>
          </Flex>
          <Flex
            gap="16px"
            direction="row"
            width="280px"
            height="22px"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Rating38303618")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(13,26,38,1)"
              lineHeight="22px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              textDecoration="underline"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Purpose"
              {...getOverrideProps(overrides, "Reviews38303620")}
            ></Text>
            <Rating
              width="unset"
              height="unset"
              shrink="0"
              size="small"
              {...getOverrideProps(overrides, "Rating38303619")}
            ></Rating>
          </Flex>
          <Flex
            gap="16px"
            direction="row"
            width="280px"
            height="22px"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Rating38303631")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(13,26,38,1)"
              lineHeight="22px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              textDecoration="underline"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Structure"
              {...getOverrideProps(overrides, "Reviews38303633")}
            ></Text>
            <Rating
              width="unset"
              height="unset"
              shrink="0"
              size="small"
              {...getOverrideProps(overrides, "Rating38303632")}
            ></Rating>
          </Flex>
          <Flex
            gap="16px"
            direction="row"
            width="280px"
            height="22px"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Rating38303644")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(13,26,38,1)"
              lineHeight="22px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              textDecoration="underline"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Style"
              {...getOverrideProps(overrides, "Reviews38303646")}
            ></Text>
            <Rating
              width="unset"
              height="unset"
              shrink="0"
              size="small"
              {...getOverrideProps(overrides, "Rating38303645")}
            ></Rating>
          </Flex>
          <Flex
            gap="16px"
            direction="row"
            width="280px"
            height="22px"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Rating38303657")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(13,26,38,1)"
              lineHeight="22px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              textDecoration="underline"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              grow="1"
              shrink="1"
              basis="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Vocabulary"
              {...getOverrideProps(overrides, "Reviews38303659")}
            ></Text>
            <Rating
              width="unset"
              height="unset"
              shrink="0"
              size="small"
              {...getOverrideProps(overrides, "Rating38303658")}
            ></Rating>
          </Flex>
        </Flex>
      </Flex>
      <Divider
        height="unset"
        shrink="0"
        alignSelf="stretch"
        size="default"
        orientation="vertical"
        {...getOverrideProps(overrides, "Divider38303505")}
      ></Divider>
      <Flex
        gap="12px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        grow="1"
        shrink="1"
        basis="0"
        alignSelf="stretch"
        position="relative"
        padding="24px 24px 24px 24px"
        backgroundColor="rgba(255,255,255,1)"
        display="flex"
        {...getOverrideProps(overrides, "Card Area38303506")}
      >
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 441")}
        >
          <Text
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="700"
            color="rgba(92,102,112,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Original  Writing"
            {...getOverrideProps(overrides, "Original Writing")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="400"
            color="rgba(92,102,112,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Original sdfdfdfdsfsdfgfdgfdgfdgfgfgfgfgf dgdfgfdgfgfhgfhg fhgf hgfhgfhgsdfdsfsdfdsfdd gfdgfdgfdgfgfgfgfgf dgdfgfdgfgfhgfhg fhgf hgf hgf hgsd fdsfs dfdsfd"
            {...getOverrideProps(overrides, "OriginalContent")}
          ></Text>
        </Flex>
        <Divider
          height="unset"
          shrink="0"
          alignSelf="stretch"
          size="small"
          orientation="vertical"
          {...getOverrideProps(overrides, "Divider38303884")}
        ></Divider>
        <Flex
          gap="10px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 442")}
        >
          <Text
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="700"
            color="rgba(92,102,112,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Rewrite"
            {...getOverrideProps(overrides, "Rewrite")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="400"
            color="rgba(92,102,112,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Original sdfdfdfdsfsdfgfdgfdgfdgfgfgfgfgf dgdfgfdgfgfhgfhg fhgf hgfhgfhgsdfdsfsdfdsfdd gfdgfdgfdgfgfgfgfgf dgdfgfdgfgfhgfhg fhgf hgf hgf hgsd fdsfs dfdsfd"
            {...getOverrideProps(overrides, "RewriteContent")}
          ></Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
