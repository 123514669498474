/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import ReviewCard from "./ReviewCard";
import { Flex } from "@aws-amplify/ui-react";
export default function Reviews(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        ReviewCard39025281: {},
        ReviewCard39025335: {},
        ReviewCard39025357: {},
        "Frame 421": {},
        ReviewCard39025381: {},
        ReviewCard39025403: {},
        ReviewCard39025425: {},
        "Frame 422": {},
        Reviews: {},
      },
      variantValues: { breakpoint: "large" },
    },
    {
      overrides: {
        ReviewCard39025281: {},
        ReviewCard39025335: {},
        ReviewCard39025357: {},
        "Frame 421": {
          direction: "column",
          height: "480px",
          justifyContent: "center",
          alignItems: "flex-start",
        },
        ReviewCard39025381: {},
        ReviewCard39025403: {},
        ReviewCard39025425: {},
        "Frame 422": {
          direction: "column",
          height: "480px",
          justifyContent: "center",
          alignItems: "flex-start",
        },
        Reviews: { direction: "row", width: "768px", height: "504px" },
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        ReviewCard39025281: {},
        ReviewCard39025335: {},
        ReviewCard39025357: {},
        "Frame 421": {
          direction: "column",
          height: "480px",
          justifyContent: "center",
          alignItems: "flex-start",
          shrink: "0",
        },
        ReviewCard39025381: {},
        ReviewCard39025403: {},
        ReviewCard39025425: {},
        "Frame 422": {
          direction: "column",
          height: "480px",
          justifyContent: "center",
          alignItems: "flex-start",
          shrink: "0",
        },
        Reviews: { width: "480px", height: "984px" },
      },
      variantValues: { breakpoint: "small" },
    },
    {
      overrides: {
        ReviewCard39025281: {},
        ReviewCard39025335: {},
        ReviewCard39025357: {},
        "Frame 421": {
          direction: "column",
          height: "480px",
          justifyContent: "center",
          alignItems: "flex-start",
          shrink: "0",
        },
        ReviewCard39025381: {},
        ReviewCard39025403: {},
        ReviewCard39025425: {},
        "Frame 422": {
          direction: "column",
          height: "480px",
          justifyContent: "center",
          alignItems: "flex-start",
          shrink: "0",
        },
        Reviews: { width: "480px", height: "984px" },
      },
      variantValues: { breakpoint: "base" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="0"
      direction="column"
      width="1440px"
      height="360px"
      justifyContent="flex-start"
      alignItems="flex-start"
      overflow="hidden"
      position="relative"
      padding="12px 12px 12px 12px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "Reviews")}
    >
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        overflow="hidden"
        grow="1"
        shrink="1"
        basis="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 421")}
      >
        <ReviewCard
          display="flex"
          gap="8px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="12px 10px 12px 10px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "ReviewCard39025281")}
        ></ReviewCard>
        <ReviewCard
          display="flex"
          gap="8px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="12px 10px 12px 10px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "ReviewCard39025335")}
        ></ReviewCard>
        <ReviewCard
          display="flex"
          gap="8px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="12px 10px 12px 10px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "ReviewCard39025357")}
        ></ReviewCard>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        overflow="hidden"
        grow="1"
        shrink="1"
        basis="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 422")}
      >
        <ReviewCard
          display="flex"
          gap="8px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="12px 10px 12px 10px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "ReviewCard39025381")}
        ></ReviewCard>
        <ReviewCard
          display="flex"
          gap="8px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="12px 10px 12px 10px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "ReviewCard39025403")}
        ></ReviewCard>
        <ReviewCard
          display="flex"
          gap="8px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          overflow="hidden"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          padding="12px 10px 12px 10px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "ReviewCard39025425")}
        ></ReviewCard>
      </Flex>
    </Flex>
  );
}
