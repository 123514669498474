import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { ThemeProvider } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import '@aws-amplify/ui-react/styles.css';
import studioTheme from './ui-components/studioTheme_customerised';

Amplify.configure({
  ...awsconfig,
  // Auth: {
  //   identityPoolId: 'ap-southeast-2:46331458-9ba2-4903-88c3-005164c6b1c8', // REQUIRED - Amazon Cognito Identity Pool ID
  //   region: 'ap-southeast-2', // REQUIRED - Amazon Cognito Region
  //   userPoolId: 'ap-southeast-2_cie3rznW2', // OPTIONAL - Amazon Cognito User Pool ID
  //   userPoolWebClientId: '6fnisl4q43t9cht7qn8dtmve1q', // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  // },
  // API: {
  //   endpoints: [
  //     {
  //       name: 'api',
  //       endpoint:
  //         'https://c9zmhhooac.execute-api.ap-southeast-2.amazonaws.com/prod',
  //     },
  //   ],
  // },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={studioTheme}>
    <App />
  </ThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
