/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import {
  Button,
  Flex,
  Text,
  TextAreaField,
  TextField,
} from "@aws-amplify/ui-react";
export default function MarketingFooterResponsive(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        text1: {},
        YourEmail: {},
        TextAreaField: {},
        Button: {},
        sdfdfd: {},
        "Frame 313": {},
        Review: {},
        "Writing Sample": {},
        "Writing tips": {},
        "Frame 404": {},
        AboutUs: {},
        Careers: {},
        Investors: {},
        TermCondition: {},
        Privacy: {},
        "Tempor incididunt ut labore": {},
        "Et dolore magna": {},
        "Frame 403": {},
        "Frame 405": {},
        "Frame 443": {},
        text4: {},
        "Lorem ipsum dolor sit amet 2021": {},
        MarketingFooterResponsive: {},
      },
      variantValues: { breakpoint: "xl" },
    },
    {
      overrides: {
        text1: {},
        YourEmail: {},
        TextAreaField: {},
        Button: {},
        sdfdfd: {},
        "Frame 313": {},
        Review: {},
        "Writing Sample": {},
        "Writing tips": {},
        "Frame 404": {},
        AboutUs: {},
        Careers: {},
        Investors: {},
        TermCondition: {},
        Privacy: {},
        "Tempor incididunt ut labore": {},
        "Et dolore magna": {},
        "Frame 403": {},
        "Frame 405": {},
        "Frame 443": { gap: "40px", width: "928px" },
        text4: {},
        "Lorem ipsum dolor sit amet 2021": {},
        MarketingFooterResponsive: { width: "992px" },
      },
      variantValues: { breakpoint: "large" },
    },
    {
      overrides: {
        text1: {},
        YourEmail: {},
        TextAreaField: {},
        Button: {},
        sdfdfd: {},
        "Frame 313": { width: "442px" },
        Review: {},
        "Writing Sample": {},
        "Writing tips": {},
        "Frame 404": {},
        AboutUs: {},
        Careers: {},
        Investors: {},
        TermCondition: {},
        Privacy: {},
        "Tempor incididunt ut labore": {},
        "Et dolore magna": {},
        "Frame 403": {},
        "Frame 405": { height: "unset" },
        "Frame 443": { gap: "20px" },
        text4: {},
        "Lorem ipsum dolor sit amet 2021": {},
        MarketingFooterResponsive: { width: "768px" },
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        text1: { width: "unset", alignSelf: "stretch" },
        YourEmail: {},
        TextAreaField: {},
        Button: {},
        sdfdfd: {},
        "Frame 313": { height: "unset", alignSelf: "stretch" },
        Review: {},
        "Writing Sample": {},
        "Writing tips": {},
        "Frame 404": {},
        AboutUs: {},
        Careers: {},
        Investors: {},
        TermCondition: {},
        Privacy: {},
        "Tempor incididunt ut labore": {},
        "Et dolore magna": {},
        "Frame 403": {},
        "Frame 405": { height: "unset", alignSelf: "stretch" },
        "Frame 443": { gap: "30px", direction: "column" },
        text4: { alignSelf: "stretch" },
        "Lorem ipsum dolor sit amet 2021": {},
        MarketingFooterResponsive: { width: "480px", height: "650px" },
      },
      variantValues: { breakpoint: "base" },
    },
    {
      overrides: {
        text1: { width: "unset", alignSelf: "stretch" },
        YourEmail: {},
        TextAreaField: {},
        Button: {},
        sdfdfd: {},
        "Frame 313": { height: "unset", alignSelf: "stretch" },
        Review: {},
        "Writing Sample": {},
        "Writing tips": {},
        "Frame 404": {},
        AboutUs: {},
        Careers: {},
        Investors: {},
        TermCondition: {},
        Privacy: {},
        "Tempor incididunt ut labore": {},
        "Et dolore magna": {},
        "Frame 403": {},
        "Frame 405": { height: "unset", alignSelf: "stretch" },
        "Frame 443": { gap: "30px", direction: "column" },
        text4: { alignSelf: "stretch" },
        "Lorem ipsum dolor sit amet 2021": {},
        MarketingFooterResponsive: { width: "480px", height: "650px" },
      },
      variantValues: { breakpoint: "small" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="20px"
      direction="column"
      width="1280px"
      height="366px"
      justifyContent="flex-start"
      alignItems="flex-start"
      overflow="hidden"
      position="relative"
      padding="20px 32px 20px 32px"
      backgroundColor="rgba(46,79,79,1)"
      display="flex"
      {...rest}
      {...getOverrideProps(overrides, "MarketingFooterResponsive")}
    >
      <Flex
        gap="200px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        overflow="hidden"
        grow="1"
        shrink="1"
        basis="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 443")}
      >
        <Flex
          gap="16px"
          direction="column"
          width="unset"
          height="282px"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 313")}
        >
          <Text
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="400"
            color="rgba(255,255,255,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="471px"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="This website is designed to help students and people who what to improve their writing skills"
            {...getOverrideProps(overrides, "text1")}
          ></Text>
          <TextField
            width="300px"
            height="unset"
            placeholder="Your Email"
            shrink="0"
            size="default"
            isDisabled={false}
            labelHidden={true}
            variation="default"
            {...getOverrideProps(overrides, "YourEmail")}
          ></TextField>
          <TextAreaField
            width="300px"
            height="unset"
            placeholder="Message"
            shrink="0"
            size="default"
            isDisabled={false}
            labelHidden={true}
            variation="default"
            {...getOverrideProps(overrides, "TextAreaField")}
          ></TextAreaField>
          <Button
            width="unset"
            height="unset"
            shrink="0"
            size="default"
            isDisabled={false}
            variation="primary"
            children="Contact Us"
            {...getOverrideProps(overrides, "Button")}
          ></Button>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color="rgba(255,255,255,1)"
            lineHeight="24px"
            textAlign="left"
            display="none"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.01px"
            width="471px"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="sdfdfd"
            {...getOverrideProps(overrides, "sdfdfd")}
          ></Text>
        </Flex>
        <Flex
          gap="24px"
          direction="row"
          width="unset"
          height="131px"
          justifyContent="flex-end"
          alignItems="flex-start"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 405")}
        >
          <Flex
            gap="16px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 404")}
          >
            <Button
              width="unset"
              height="unset"
              padding="0px 0px 0px 0px"
              shrink="0"
              size="default"
              isDisabled={false}
              variation="link"
              children="Review"
              {...getOverrideProps(overrides, "Review")}
            ></Button>
            <Button
              width="unset"
              height="unset"
              padding="0px 0px 0px 0px"
              shrink="0"
              size="default"
              isDisabled={false}
              variation="link"
              children="Writing Sample"
              {...getOverrideProps(overrides, "Writing Sample")}
            ></Button>
            <Button
              width="unset"
              height="unset"
              padding="0px 0px 0px 0px"
              shrink="0"
              size="default"
              isDisabled={false}
              variation="link"
              children="Writing tips"
              {...getOverrideProps(overrides, "Writing tips")}
            ></Button>
          </Flex>
          <Flex
            gap="16px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 403")}
          >
            <Button
              width="unset"
              height="unset"
              padding="0px 0px 0px 0px"
              shrink="0"
              size="default"
              isDisabled={false}
              variation="link"
              children="About Us"
              {...getOverrideProps(overrides, "AboutUs")}
            ></Button>
            <Button
              width="unset"
              height="unset"
              padding="0px 0px 0px 0px"
              shrink="0"
              size="default"
              isDisabled={false}
              variation="link"
              children="Careers"
              {...getOverrideProps(overrides, "Careers")}
            ></Button>
            <Button
              width="unset"
              height="unset"
              padding="0px 0px 0px 0px"
              shrink="0"
              size="default"
              isDisabled={false}
              variation="link"
              children="Investors"
              {...getOverrideProps(overrides, "Investors")}
            ></Button>
            <Button
              width="unset"
              height="unset"
              padding="0px 0px 0px 0px"
              shrink="0"
              size="default"
              isDisabled={false}
              variation="link"
              children="Terms & Conditions"
              {...getOverrideProps(overrides, "TermCondition")}
            ></Button>
            <Button
              width="unset"
              height="unset"
              padding="0px 0px 0px 0px"
              shrink="0"
              size="default"
              isDisabled={false}
              variation="link"
              children="Privacy Policy"
              {...getOverrideProps(overrides, "Privacy")}
            ></Button>
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(255,255,255,1)"
              lineHeight="24px"
              textAlign="left"
              display="none"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="272px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Tempor incididunt ut labore"
              {...getOverrideProps(overrides, "Tempor incididunt ut labore")}
            ></Text>
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(255,255,255,1)"
              lineHeight="24px"
              textAlign="left"
              display="none"
              direction="column"
              justifyContent="unset"
              letterSpacing="0.01px"
              width="272px"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Et dolore magna"
              {...getOverrideProps(overrides, "Et dolore magna")}
            ></Text>
          </Flex>
        </Flex>
      </Flex>
      <Text
        fontFamily="Inter"
        fontSize="16px"
        fontWeight="400"
        color="rgba(255,255,255,1)"
        lineHeight="24px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="0.01px"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Copyright © 2023 Hundred Technology Pty Ltd  ABN 49 639 745 314. All Rights Reserved."
        {...getOverrideProps(overrides, "text4")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="16px"
        fontWeight="400"
        color="rgba(255,255,255,1)"
        lineHeight="24px"
        textAlign="right"
        display="none"
        direction="column"
        justifyContent="unset"
        letterSpacing="0.01px"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Lorem ipsum dolor sit amet 2021"
        {...getOverrideProps(overrides, "Lorem ipsum dolor sit amet 2021")}
      ></Text>
    </Flex>
  );
}
