import { View, Flex, useBreakpointValue } from '@aws-amplify/ui-react';
import { TIPS } from '../data';
import { FAQItem } from '../ui-components';
export function Tips() {
  const breakpoint = useBreakpointValue({
    base: 'base',
    small: 'small',
    medium: 'medium',
    large: 'large',
    xl: 'xl',
    xxl: 'xxl',
  });

  // console.log({ breakpoint });
  return (
    <View className="App-header">
      <View
        direction="column"
        style={{
          paddingTop: 12,
          paddingBottom: 12,
          width: breakpoint === 'xxl' ? 1480 : '90%',
        }}
      >
        {TIPS.map(({ title, content }, index) => (
          <View key={`tips-${index}`}>
            <FAQItem
              overrides={{
                Container: {
                  width: '100%',
                },
                Title: {
                  fontFamily: '',
                  children: title,
                },
                Paragraph: {
                  fontFamily: '',
                  children: content,
                },
                Divider: {
                  width: '100%',
                },
              }}
            ></FAQItem>
          </View>
        ))}
        {/* <MessageBox
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
          msgHeading={msgHeading}
          msgContent={msgContent}
        /> */}
      </View>
    </View>
  );
}
