import { useState } from 'react';

import { View, Flex, useBreakpointValue } from '@aws-amplify/ui-react';
import { WritingSampleContext, WritingPannel } from '../ui-components';
import {
  ANY,
  vocabularyInit,
  wordsCountInit,
  writeTypeInit,
  vocabularyOptions,
  writeTypeOptions,
  wordsCountOptions,
} from '../data';

import { postApi } from '../hookers/utils.js';
import { MessageBox } from '../components/MessageBox.js';

import { useProfileContext } from '../hookers/profileContext';


export function WritingSample() {
  // const { route } = useAuthenticator((context) => [context.route]);

  const [vocabulary, setVocabulary] = useState(vocabularyInit);
  const [writeType, setWriteType] = useState(writeTypeInit);
  const [wordsCount, setWordsCount] = useState(wordsCountInit);
  const [instruction, setInstruction] = useState('');
  const [content, setContent] = useState('');

  const [isGenerateInstructionLoading, setIsGenerateInstructionLoading] =
    useState(false);
  const [isGenerateSampleLoading, setIsGenerateSampleLoading] = useState(false);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [msgHeading, setMsgHeading] = useState('');
  const [msgContent, setMsgContent] = useState('');

  const { deductCredit, profile } = useProfileContext();

  const { credits } = profile || {};

  const breakpoint = useBreakpointValue({
    base: 'base',
    small: 'small',
    medium: 'medium',
    large: 'large',
    xl: 'xl',
    xxl: 'xxl',
  });

  const openModal = (heading, content) => {
    setMsgHeading(heading);
    setMsgContent(content);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const onError = (error) => {
    if (error === 'Ran out of credits') {
      openModal('Info', 'Please buy more credits to continue this operation');
    } else {
      openModal('Info', error);
    }
  };

  const generateInstructionClicked = async () => {
    if (!credits || credits <= 0) {
      openModal('Info', 'Please buy more credits to continue this operation');
      return;
    }
    setIsGenerateInstructionLoading(true);
    await postApi(
      '/api/generate_instruction',
      {
        requestBody: {
          vocabulary: vocabulary !== ANY ? vocabulary : '',
          write_type: writeType !== ANY ? writeType : '',
        },
      },
      onError,
      (data) => {
        if (data){
          const { consumedCredit, result } = data;
          setInstruction(result);
          deductCredit(consumedCredit);
        }
      },
    );
    setIsGenerateInstructionLoading(false);
  };

  const generateInstruction = async () => {
    if (instruction) {
      const confirmBox = window.confirm(
        'Will overwrite existing instructions?',
      );
      if (confirmBox !== true) {
        return;
      }
      // openModal('Warning', 'Will overwrite existing instructions');
    }
    await generateInstructionClicked();
  };

  const generateSample = async () => {
    if (!credits || credits <= 0) {
      openModal('Info', 'Please buy more credits to continue this operation');
      return;
    }
    setIsGenerateSampleLoading(true);
    await postApi(
      '/api/generate_sample',
      {
        requestBody: {
          vocabulary: vocabulary !== ANY ? vocabulary : '',
          write_type: writeType !== ANY ? writeType : '',
          words_count: wordsCount !== ANY ? wordsCount : '',
          instruction,
        },
      },
      onError,
      (data) => {
        if (data) {
          const { consumedCredit, result } = data;
          setContent(result);
          deductCredit(consumedCredit);
        }
      },
    );
    setIsGenerateSampleLoading(false);
  };

  // console.log({ breakpoint });

  return (
    <View className="App-header">
      <Flex
        direction="column"
        style={{
          paddingTop: 12,
          paddingBottom: 12,
          width: breakpoint === 'xxl' ? 1480 : '90%',
        }}
      >
        <WritingSampleContext
          breakpoint={breakpoint}
          overrides={{
            WritingSampleContext: {
              width: 'unset',
            },
            TextAreaField: {
              label: 'Instructions',
              value: instruction,
              onChange: (e) => setInstruction(e.currentTarget.value),
              rows: 8,
            },
            SelectField38113340: {
              options: vocabularyOptions,
              placeholder: '',
              value: vocabulary,
              onChange: (e) => setVocabulary(e.target.value),
            },
            SelectField38113341: {
              options: writeTypeOptions,
              placeholder: '',
              value: writeType,
              onChange: (e) => setWriteType(e.target.value),
            },
            SelectField38113342: {
              options: wordsCountOptions,
              placeholder: '',
              value: wordsCount,
              onChange: (e) => setWordsCount(e.target.value),
            },
            Button38113343: {
              onClick: generateInstruction,
              isLoading: isGenerateInstructionLoading,
              ...(['base'].includes(breakpoint) ? { fontSize: '16px' } : {}),
            },
            Button38113378: {
              onClick: generateSample,
              isLoading: isGenerateSampleLoading,
              ...(['base'].includes(breakpoint) ? { fontSize: '16px' } : {}),
            },
          }}
        />
        <WritingPannel
          overrides={{
            WritingPannel: {
              width: 'unset',
            },
            TextAreaField: {
              isDisabled: true,
              // labelHidden: true,
              label: 'Writing Sample',
              value: content,
              rows: 20,
            },
          }}
        />
        <MessageBox
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
          msgHeading={msgHeading}
          msgContent={msgContent}
        />
      </Flex>
    </View>
  );
}
