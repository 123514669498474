import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { View, Flex, useBreakpointValue } from '@aws-amplify/ui-react';
import { CreditsButtons } from '../ui-components';
import { MessageBox } from '../components/MessageBox.js';

import { getApi, postApi } from '../hookers/utils';
import { useProfileContext } from '../hookers/profileContext';

const priceIdCreditMapping = {
  10:'price_1N5OHqHEoRYpDhgyp1GZbbsz',
  20:'price_1NBZvnHEoRYpDhgyjEOyUBPJ',
  30:'price_1NBa5lHEoRYpDhgyHqkkYuRR',
  40:'price_1NBa6THEoRYpDhgyvgorj0pC',
  50:'price_1NBa74HEoRYpDhgyl0qjTE9M',
  60:'price_1NBa8pHEoRYpDhgykcBReMVr',
  70:'price_1NBa9NHEoRYpDhgyD2VNjolO',
  80:'price_1NBaAKHEoRYpDhgywZltYJYA',
  90:'price_1NBaAvHEoRYpDhgyuBgIkKz6',
  100:'price_1NBaBYHEoRYpDhgyi65ePuHK',
  200:'price_1NBaC3HEoRYpDhgyAdTyqYlH',
  500:'price_1NBaCeHEoRYpDhgyppYtiOhE',
  1000:'price_1NBaDdHEoRYpDhgyJMOm5knx',
}

export function BuyCredits() {
  const [isPurchaseLoading, setIsPurchaseLoading] = useState(false);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [msgHeading, setMsgHeading] = useState('');
  const [msgContent, setMsgContent] = useState('');

  const { purchaseCredit } = useProfileContext();

  const openModal = (heading, content) => {
    setMsgHeading(heading);
    setMsgContent(content);
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const breakpoint = useBreakpointValue({
    base: 'base',
    small: 'small',
    medium: 'medium',
    large: 'large',
    xl: 'xl',
    xxl: 'xxl',
  });
  // console.log({ breakpoint });

  //// for eway permission error
  // useEffect(() => {
  //   const script = document.createElement('script');

  //   script.src = 'https://secure.ewaypayments.com/scripts/eCrypt.js';
  //   script.async = true;
  //   script.class = 'eway-paynow-button';
  //   script['data-publicapikey'] = 'epk-4CF0B616-4537-4420-8AC9-9DAA70908C20';
  //   script['data-amount'] = '1000';
  //   script['data-currency'] = 'AUD';

  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  /// for stripe
  const purchase_dollar = (price) => async () => {
    setIsPurchaseLoading(true);
    let error;
    try {
      await postApi(
        '/stripe/create-checkout-session', 
        {
          price_id: priceIdCreditMapping[price],
        }, 
        (error)=>{
          // debugger;
          openModal('Info', error);
        },
        (data)=>{
          window.location.href = data.url
          // purchaseCredit(10);
        });
    } catch (err) {
      console.log({ err });
      error = err?.response?.data?.error || err;
    }

    setIsPurchaseLoading(false);
    if (error) {
      openModal('Info', error);
    }
  };

  return (
    <View className="App-header">
      <Flex
        direction="column"
        style={{
          width: breakpoint === 'xxl' ? 1480 : '90%',
        }}
      >
        {/* <Helmet>
          <script
            src="https://secure.ewaypayments.com/scripts/eCrypt.js"
            class="eway-paynow-button"
            data-publicapikey="epk-4CF0B616-4537-4420-8AC9-9DAA70908C20"
            data-amount="1000"
            data-currency="AUD"
          ></script>
        </Helmet> */}
        <CreditsButtons
          breakpoint={breakpoint}
          overrides={{
            CreditsButtons: {
              width: 'unset',
            },
            Button38073242: {
              onClick: purchase_dollar(10),
              isLoading: isPurchaseLoading,
            },
            Button38063209: {
              onClick: purchase_dollar(20),
              isLoading: isPurchaseLoading,
            },
            Button38083264: {
              onClick: purchase_dollar(30),
              isLoading: isPurchaseLoading,
            },
            Button38083268: {
              onClick: purchase_dollar(40),
              isLoading: isPurchaseLoading,
            },
            Button38083247: {
              onClick: purchase_dollar(50),
              isLoading: isPurchaseLoading,
            },
            Button38083272: {
              onClick: purchase_dollar(60),
              isLoading: isPurchaseLoading,
            },
            Button38083276: {
              onClick: purchase_dollar(70),
              isLoading: isPurchaseLoading,
            },
            Button38083280: {
              onClick: purchase_dollar(80),
              isLoading: isPurchaseLoading,
            },
            Button38083285: {
              onClick: purchase_dollar(90),
              isLoading: isPurchaseLoading,
            },
            Button38083251: {
              onClick: purchase_dollar(100),
              isLoading: isPurchaseLoading,
            },
            Button38083255: {
              onClick: purchase_dollar(200),
              isLoading: isPurchaseLoading,
            },
            Button38083260: {
              onClick: purchase_dollar(500),
              isLoading: isPurchaseLoading,
            },
            Button38083289: {
              onClick: purchase_dollar(1000),
              isLoading: isPurchaseLoading,
            }

          }}
        />
      </Flex>
      <MessageBox
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        msgHeading={msgHeading}
        msgContent={msgContent}
      />
    </View>
  );
}
