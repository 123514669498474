import { View, Flex, useBreakpointValue } from '@aws-amplify/ui-react';
import {} from '../ui-components';
export function AboutMe() {
  const breakpoint = useBreakpointValue({
    base: 'base',
    small: 'small',
    medium: 'medium',
    large: 'large',
    xl: 'xl',
    xxl: 'xxl',
  });
  console.log({ breakpoint });

  return (
    <View className="App-header">
      <Flex
        direction="column"
        style={{
          width: breakpoint === 'xxl' ? 1480 : '90%',

          paddingTop: 12,
          paddingBottom: 12,
        }}
      >
        <Flex
          direction="column"
          style={{ padding: 24, backgroundColor: 'rgba(255,255,255,1)' }}
        >
          <h1>About Us</h1>
          <p>
            Welcome to writing booster, we are dedicated to helping people
            improve their writing through effective article reviews and
            rewrites. We have AI-powered language model designed to help you
            become a better writer.
          </p>
          <p>
            Writing is an essential skill that everyone needs in today's world.
            Whether you are a student, professional, or entrepreneur, your
            ability to communicate effectively through writing can make all the
            difference in your success. But sometimes, even the best writers
            need a little help to take their writing to the next level.
          </p>
          <p>
            That's where writing booster come in. Writing booster offer
            comprehensive article review and rewriting services that can help
            you refine your writing style, strengthen your arguments, and
            communicate your ideas more clearly and effectively.
          </p>
          <h2>How it works</h2>
          <p>
            Here's how it works: You simply upload your article to the platform,
            and website will use advanced natural language processing
            capabilities to analyze your writing and provide you with detailed
            feedback on what works well and where you can improve. I will also
            provide suggestions for rephrasing sentences, improving word choice,
            and enhancing the overall flow and coherence of your writing.
          </p>
          <p>
            My goal is to help you become a more confident and capable writer by
            giving you the tools you need to communicate your ideas effectively.
            With my help, you can take your writing to the next level and
            achieve your goals, whether that means getting better grades,
            impressing your boss, or reaching a wider audience with your
            writing.
          </p>
          <h2>Writing history management</h2>
          <p>
            In addition to providing you with personalized article review and
            rewriting services, my website also keeps a record of your previous
            submissions, including the original article and the revised
            versions. This history can be a valuable resource for you, as it
            allows you to track your progress as a writer and see how your
            writing has evolved over time.
          </p>
          <p>
            By accessing your submission history, you can review the feedback
            and suggestions provided by my platform and see how you have
            incorporated those changes into your writing. You can also compare
            your original article to the revised versions to see how your
            writing has improved and identify areas where you may still need to
            work on.
          </p>
          <p>
            Keeping track of your submission history can be an effective way to
            monitor your progress as a writer and stay motivated to continue
            improving your writing skills. It can also provide you with a
            valuable record of your work, which you can use to demonstrate your
            writing abilities to potential employers, clients, or other
            stakeholders.
          </p>
          <p>
            At my website, we take privacy and data security very seriously. All
            of your submissions and revision history are securely stored and
            kept confidential, ensuring that your personal information and work
            remain private and protected.
          </p>
          <p>
            So what are you waiting for? Let's get started! Upload your article
            today and experience the power of my article review and rewriting
            services for yourself.
          </p>
        </Flex>
      </Flex>
    </View>
  );
}
